var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SocketMessageStatus } from "./constants";
var BaseMessage = /** @class */ (function () {
    function BaseMessage(socket, data) {
        this.socket = socket;
        this.createdAt = new Date();
        this.action = data === null || data === void 0 ? void 0 : data.action;
        this.content = data === null || data === void 0 ? void 0 : data.content;
        this.correlationId = data === null || data === void 0 ? void 0 : data.correlationId;
    }
    BaseMessage.prototype.toJSON = function () {
        return {
            createdAt: this.createdAt,
            action: this.action,
            correlationId: this.correlationId,
            content: this.content
        };
    };
    return BaseMessage;
}());
export { BaseMessage };
var OutgoingMessage = /** @class */ (function (_super) {
    __extends(OutgoingMessage, _super);
    function OutgoingMessage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.content = _this.content;
        return _this;
    }
    return OutgoingMessage;
}(BaseMessage));
export { OutgoingMessage };
var BaseIncomingMessage = /** @class */ (function (_super) {
    __extends(BaseIncomingMessage, _super);
    function BaseIncomingMessage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.content = _this.content;
        return _this;
    }
    return BaseIncomingMessage;
}(BaseMessage));
export { BaseIncomingMessage };
var IncomingMessageWithoutResponse = /** @class */ (function (_super) {
    __extends(IncomingMessageWithoutResponse, _super);
    function IncomingMessageWithoutResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return IncomingMessageWithoutResponse;
}(BaseIncomingMessage));
export { IncomingMessageWithoutResponse };
var IncomingMessageWithResponse = /** @class */ (function (_super) {
    __extends(IncomingMessageWithResponse, _super);
    function IncomingMessageWithResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IncomingMessageWithResponse.prototype.emitSuccess = function (content) {
        if (!this.correlationId)
            return false;
        return this.socket.emitResponseMessage(this.correlationId, SocketMessageStatus.SUCCESS, content);
    };
    IncomingMessageWithResponse.prototype.emitError = function (data, message, code) {
        if (!this.correlationId)
            return false;
        return this.socket.emitResponseMessage(this.correlationId, SocketMessageStatus.ERROR, {
            errorMessage: message,
            errorCode: code,
            data: data,
        });
    };
    return IncomingMessageWithResponse;
}(BaseIncomingMessage));
export { IncomingMessageWithResponse };
var ResponseMessage = /** @class */ (function () {
    function ResponseMessage(socket, data) {
        this.socket = socket;
        this.createdAt = new Date();
        this.type = data === null || data === void 0 ? void 0 : data.type;
        this.content = data === null || data === void 0 ? void 0 : data.content;
        this.correlationId = data === null || data === void 0 ? void 0 : data.correlationId;
    }
    ResponseMessage.prototype.toJSON = function () {
        return {
            createdAt: this.createdAt,
            type: this.type,
            correlationId: this.correlationId,
            content: this.content
        };
    };
    return ResponseMessage;
}());
export { ResponseMessage };
var ResponseErrorMessage = /** @class */ (function (_super) {
    __extends(ResponseErrorMessage, _super);
    function ResponseErrorMessage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ResponseErrorMessage;
}(ResponseMessage));
export { ResponseErrorMessage };
var ResultEmittedMessage = /** @class */ (function () {
    function ResultEmittedMessage(socket, command, response, error) {
        this.socket = socket;
        this.command = command;
        this.error = error;
        this.response = response;
    }
    return ResultEmittedMessage;
}());
export { ResultEmittedMessage };
