var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { activityMiddlewares } from "./commands/activity/middlewares";
import { deviceMiddlewares } from "./commands/device/middlewares";
import { meetingMiddlewares } from "./commands/meeting/middlewares";
import { organizationMiddlewares } from "./commands/organization/middlewares";
import { roomMiddlewares } from "./commands/room/middlewares";
import { vodMiddlewares } from "./commands/vod/middlewares";
import { webhooksMiddlewares } from "./commands/webhooks/middlewares";
var WebSocketMiddlewares = /** @class */ (function () {
    function WebSocketMiddlewares(socket) {
        this.socket = socket;
        this.activity = activityMiddlewares;
        this.device = deviceMiddlewares;
        this.meeting = meetingMiddlewares;
        this.organization = organizationMiddlewares;
        this.room = roomMiddlewares;
        this.vod = vodMiddlewares;
        this.webhooks = webhooksMiddlewares;
        var allMiddlewares = [
            this.activity,
            this.device,
            this.meeting,
            this.organization,
            this.room,
            this.vod,
            this.webhooks,
        ];
        this.middlewares = {
            action: Object.assign.apply(Object, __spreadArray([{}], (allMiddlewares.map(function (m) { var _a; return (_a = m === null || m === void 0 ? void 0 : m.action) !== null && _a !== void 0 ? _a : {}; })), false)),
            receive: Object.assign.apply(Object, __spreadArray([{}], (allMiddlewares.map(function (m) { var _a; return (_a = m === null || m === void 0 ? void 0 : m.receive) !== null && _a !== void 0 ? _a : {}; })), false)),
        };
    }
    WebSocketMiddlewares.prototype.processIncomingMessage = function (data) {
        if (!this.middlewares.action)
            return data;
        var middleware = this.middlewares.action[data.action];
        if (!middleware)
            return data;
        return middleware.bind(this.socket)(data);
    };
    WebSocketMiddlewares.prototype.processResponseMessage = function (action, data) {
        if (!this.middlewares.receive)
            return data;
        var middleware = this.middlewares.receive[action];
        if (!middleware)
            return data;
        return middleware.bind(this.socket)(data);
    };
    return WebSocketMiddlewares;
}());
export { WebSocketMiddlewares };
