var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import activityCommands from "./commands/activity/commands";
import deviceCommands from "./commands/device/commands";
import meetingCommands from "./commands/meeting/commands";
import organizationCommands from "./commands/organization/commands";
import roomCommands from "./commands/room/commands";
import vodCommands from "./commands/vod/commands";
import webhooksCommands from "./commands/webhooks/commands";
import whiteboardCommands from "./commands/whiteboard/commands";
var WebSocketEmitter = /** @class */ (function () {
    function WebSocketEmitter(socket) {
        this.socket = socket;
        this.activity = this.bindMethods(activityCommands);
        this.device = this.bindMethods(deviceCommands);
        this.meeting = this.bindMethods(meetingCommands);
        this.organization = this.bindMethods(organizationCommands);
        this.room = this.bindMethods(roomCommands);
        this.webhooks = this.bindMethods(webhooksCommands);
        this.vod = this.bindMethods(vodCommands);
        this.whiteboard = this.bindMethods(whiteboardCommands);
    }
    WebSocketEmitter.prototype.bindMethods = function (methods) {
        var result = __assign({}, methods);
        var methodNames = Object.keys(result);
        for (var _i = 0, methodNames_1 = methodNames; _i < methodNames_1.length; _i++) {
            var name_1 = methodNames_1[_i];
            if (typeof result[name_1] === 'function') {
                result[name_1] = result[name_1].bind(this.socket);
            }
            else if (typeof result[name_1] === 'object') {
                result[name_1] = this.bindMethods(result[name_1]);
            }
        }
        return result;
    };
    return WebSocketEmitter;
}());
export { WebSocketEmitter };
