import { SocketActionType } from "../../constants";
function getById(payload, options) {
    return this.emitMessage(SocketActionType.MEETING_GET_BY_ID, payload, options);
}
function updateById(payload, options) {
    return this.emitMessage(SocketActionType.MEETING_UPDATE_BY_ID, payload, options);
}
function join(payload, options) {
    return this.emitMessage(SocketActionType.MEETING_JOIN, payload, options);
}
function leave(payload, options) {
    return this.emitMessage(SocketActionType.MEETING_LEAVE, payload, options);
}
function exclude(payload, options) {
    return this.emitMessage(SocketActionType.MEETING_EXCLUDE, payload, options);
}
function updateSession(payload, options) {
    return this.emitMessage(SocketActionType.MEETING_SESSION_UPDATE, payload, options);
}
function publishCustomEventSession(payload, options) {
    return this.emitMessage(SocketActionType.MEETING_SESSION_CUSTOM_EVENT, payload, options);
}
function pushStatsSession(payload, options) {
    return this.emitMessage(SocketActionType.MEETING_SESSION_STATS, payload, options);
}
function updateSessionMedia(payload, options) {
    return this.emitMessage(SocketActionType.MEETING_SESSION_MEDIA_UPDATE, payload, options);
}
var meetingCommands = {
    getById: getById,
    updateById: updateById,
    join: join,
    leave: leave,
    exclude: exclude,
    updateSession: updateSession,
    publishCustomEventSession: publishCustomEventSession,
    pushStatsSession: pushStatsSession,
    updateSessionMedia: updateSessionMedia,
};
export default meetingCommands;
