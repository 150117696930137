export var VersionState;
(function (VersionState) {
    VersionState["UNKNOWN_VERSION"] = "unknown_version";
    VersionState["UP_TO_DATE"] = "up_to_date";
    VersionState["TO_UPDATE"] = "to_update";
    VersionState["NEWER"] = "newer";
})(VersionState || (VersionState = {}));
// ------------------------------- //
// ------------ Device ------------ // 
// ------------------------------- //
// Category of device
export var DeviceCategory;
(function (DeviceCategory) {
    DeviceCategory["CAMERA"] = "camera";
    DeviceCategory["TV"] = "tv";
    DeviceCategory["TABLET"] = "tablet";
})(DeviceCategory || (DeviceCategory = {}));
// Type of device
export var DeviceType;
(function (DeviceType) {
    DeviceType["KAST"] = "kast";
})(DeviceType || (DeviceType = {}));
// Feature of device
export var DeviceFeature;
(function (DeviceFeature) {
    DeviceFeature["CAPTURE_VIDEO"] = "capture_video";
    DeviceFeature["CAPTURE_AUDIO"] = "capture_audio";
    DeviceFeature["SOURCE_VIDEO"] = "source_video";
    DeviceFeature["SOURCE_AUDIO"] = "source_audio";
    DeviceFeature["TOUCHSCREEN"] = "touchscreen";
    DeviceFeature["WEB_INTERFACE"] = "web_interface";
    DeviceFeature["PTZ"] = "ptz";
})(DeviceFeature || (DeviceFeature = {}));
export var DeviceVideoRecordState;
(function (DeviceVideoRecordState) {
    DeviceVideoRecordState["IDLE"] = "IDLE";
    DeviceVideoRecordState["RUNNING"] = "RUNNING";
    DeviceVideoRecordState["ERROR"] = "ERROR";
    DeviceVideoRecordState["INTERRUPTED"] = "INTERRUPTED";
    DeviceVideoRecordState["STOPPED"] = "STOPPED";
})(DeviceVideoRecordState || (DeviceVideoRecordState = {}));
export var DeviceUpdateOnSessionEventName;
(function (DeviceUpdateOnSessionEventName) {
    DeviceUpdateOnSessionEventName["DOWNLOAD_STARTED"] = "download_started";
    DeviceUpdateOnSessionEventName["DOWNLOAD_COMPLETED"] = "download_completed";
    DeviceUpdateOnSessionEventName["DOWNLOAD_FAILED"] = "download_failed";
    DeviceUpdateOnSessionEventName["INSTALL"] = "install";
    DeviceUpdateOnSessionEventName["ABORT"] = "abort";
    DeviceUpdateOnSessionEventName["FAILED"] = "failed";
    DeviceUpdateOnSessionEventName["CREATED"] = "created";
})(DeviceUpdateOnSessionEventName || (DeviceUpdateOnSessionEventName = {}));
export var DeviceUpdateSessionStatus;
(function (DeviceUpdateSessionStatus) {
    DeviceUpdateSessionStatus["WAITING"] = "waiting";
    DeviceUpdateSessionStatus["DOWNLOADING"] = "downloading";
    DeviceUpdateSessionStatus["WAITING_FOR_INSTALL"] = "waiting_for_install";
    DeviceUpdateSessionStatus["INSTALLING"] = "installing";
    DeviceUpdateSessionStatus["DONE"] = "done";
    DeviceUpdateSessionStatus["FAILED"] = "failed";
    DeviceUpdateSessionStatus["ABORTED"] = "aborted";
})(DeviceUpdateSessionStatus || (DeviceUpdateSessionStatus = {}));
export var DeviceUpdateSessionMode;
(function (DeviceUpdateSessionMode) {
    DeviceUpdateSessionMode["MANDATORY"] = "mandatory";
    DeviceUpdateSessionMode["SILENT"] = "silent";
})(DeviceUpdateSessionMode || (DeviceUpdateSessionMode = {}));
export var DeviceUpdateDownloadState;
(function (DeviceUpdateDownloadState) {
    DeviceUpdateDownloadState["COMPLETE"] = "complete";
    DeviceUpdateDownloadState["FAILED"] = "failed";
})(DeviceUpdateDownloadState || (DeviceUpdateDownloadState = {}));
export var DeviceSceneId;
(function (DeviceSceneId) {
    DeviceSceneId[DeviceSceneId["MIXED"] = 0] = "MIXED";
    DeviceSceneId[DeviceSceneId["CAMERA_ONLY"] = 1] = "CAMERA_ONLY";
    DeviceSceneId[DeviceSceneId["INPUT_HDMI_ONLY"] = 2] = "INPUT_HDMI_ONLY";
    DeviceSceneId[DeviceSceneId["HALF_MODE"] = 3] = "HALF_MODE";
})(DeviceSceneId || (DeviceSceneId = {}));
export var DeviceViewId;
(function (DeviceViewId) {
    DeviceViewId[DeviceViewId["SPEAKER"] = 0] = "SPEAKER";
    DeviceViewId[DeviceViewId["FULL"] = 1] = "FULL";
    DeviceViewId[DeviceViewId["MEDIUM"] = 2] = "MEDIUM";
})(DeviceViewId || (DeviceViewId = {}));
export var DeviceMoveDirection;
(function (DeviceMoveDirection) {
    DeviceMoveDirection["UP"] = "up";
    DeviceMoveDirection["DOWN"] = "down";
    DeviceMoveDirection["LEFT"] = "left";
    DeviceMoveDirection["RIGHT"] = "right";
})(DeviceMoveDirection || (DeviceMoveDirection = {}));
export var DeviceZoomDirection;
(function (DeviceZoomDirection) {
    DeviceZoomDirection["ZOOM_IN"] = "zoomIn";
    DeviceZoomDirection["ZOOM_OUT"] = "zoomOut";
})(DeviceZoomDirection || (DeviceZoomDirection = {}));
// ------------------------------- //
// ------------ User ------------ // 
// ------------------------------- //
export var UserRole;
(function (UserRole) {
    UserRole["KADMIN"] = "kadmin";
    UserRole["ADMIN"] = "admin";
    UserRole["TECHNICIAN"] = "technician";
    UserRole["ORGANIZER"] = "organizer";
    UserRole["ATTENDEE"] = "attendee";
    UserRole["GUEST"] = "guest";
})(UserRole || (UserRole = {}));
// ------------------------------- //
// ------------ Message ------------ // 
// ------------------------------- //
export var MessageReactionType;
(function (MessageReactionType) {
    MessageReactionType["thumbsUp"] = "thumbs_up";
    MessageReactionType["thumbsDown"] = "thumbs_down";
    MessageReactionType["raisedHand"] = "raised_hand";
    MessageReactionType["clap"] = "clap"; // 👏
})(MessageReactionType || (MessageReactionType = {}));
// ------------------------------- //
// ------------ Activity ------------ // 
// ------------------------------- //
export var ActivityVideoStatus;
(function (ActivityVideoStatus) {
    ActivityVideoStatus["Inactive"] = "inactive";
    ActivityVideoStatus["Starting"] = "starting";
    ActivityVideoStatus["Active"] = "active";
    ActivityVideoStatus["Stopping"] = "stopping";
})(ActivityVideoStatus || (ActivityVideoStatus = {}));
export var ActivityExternalProvider;
(function (ActivityExternalProvider) {
    ActivityExternalProvider["MOODLE"] = "moodle";
    ActivityExternalProvider["GOOGLE_CALENDAR"] = "google_calendar";
    ActivityExternalProvider["CUSTOM"] = "custom";
})(ActivityExternalProvider || (ActivityExternalProvider = {}));
export var ActivityInteractionMediaProvider;
(function (ActivityInteractionMediaProvider) {
    ActivityInteractionMediaProvider["OVENMEDIA"] = "ovenmedia";
})(ActivityInteractionMediaProvider || (ActivityInteractionMediaProvider = {}));
export var ActivityInteractionCancelCode;
(function (ActivityInteractionCancelCode) {
    ActivityInteractionCancelCode[ActivityInteractionCancelCode["UNKNWON"] = 0] = "UNKNWON";
    ActivityInteractionCancelCode[ActivityInteractionCancelCode["USER_LEAVE_ACTIVITY"] = 1] = "USER_LEAVE_ACTIVITY";
    ActivityInteractionCancelCode[ActivityInteractionCancelCode["DEVICE_IS_DISCONNECTED"] = 2] = "DEVICE_IS_DISCONNECTED";
    ActivityInteractionCancelCode[ActivityInteractionCancelCode["USER_LOSE_WEBRTC_CONNECTION"] = 3] = "USER_LOSE_WEBRTC_CONNECTION";
    ActivityInteractionCancelCode[ActivityInteractionCancelCode["DEVICE_LOSE_WEBRTC_CONNECTION"] = 4] = "DEVICE_LOSE_WEBRTC_CONNECTION";
    ActivityInteractionCancelCode[ActivityInteractionCancelCode["WEBRTC_ERROR"] = 5] = "WEBRTC_ERROR";
    ActivityInteractionCancelCode[ActivityInteractionCancelCode["REQUEST_MEDIA_ERROR"] = 6] = "REQUEST_MEDIA_ERROR";
    ActivityInteractionCancelCode[ActivityInteractionCancelCode["INTERNAL_ERROR"] = 7] = "INTERNAL_ERROR";
    ActivityInteractionCancelCode[ActivityInteractionCancelCode["SERVER_RESTART"] = 8] = "SERVER_RESTART";
})(ActivityInteractionCancelCode || (ActivityInteractionCancelCode = {}));
export var ActivityInteractionStatus;
(function (ActivityInteractionStatus) {
    ActivityInteractionStatus["WAITING_RESPONSE"] = "waiting_response";
    ActivityInteractionStatus["WAITING_MEDIA"] = "waiting_media";
    ActivityInteractionStatus["WAITING_TO_JOIN"] = "waiting_to_join";
    ActivityInteractionStatus["RUNNING"] = "running";
    ActivityInteractionStatus["REFUSED"] = "refused";
    ActivityInteractionStatus["TERMINATED"] = "terminated";
    ActivityInteractionStatus["CANCELED"] = "canceled";
})(ActivityInteractionStatus || (ActivityInteractionStatus = {}));
export var ActivityInteractionType;
(function (ActivityInteractionType) {
    ActivityInteractionType["MEDIA_DEVICE"] = "media_device";
})(ActivityInteractionType || (ActivityInteractionType = {}));
export var ActivityInteractionEventType;
(function (ActivityInteractionEventType) {
    ActivityInteractionEventType["CREATED"] = "created";
    ActivityInteractionEventType["REQUEST"] = "request";
    ActivityInteractionEventType["REQUEST_ACCEPTED"] = "request_accepted";
    ActivityInteractionEventType["REQUEST_DECLINED"] = "request_declined";
    ActivityInteractionEventType["MEDIA_IS_READY"] = "media_is_ready";
    ActivityInteractionEventType["JOINED"] = "joined";
    ActivityInteractionEventType["STOPPED"] = "stopped";
    ActivityInteractionEventType["CANCELED"] = "canceled";
})(ActivityInteractionEventType || (ActivityInteractionEventType = {}));
// ------------------------------- //
// ------------ MEETING ------------ // 
// ------------------------------- //
export var MeetingCreatedByModel;
(function (MeetingCreatedByModel) {
    MeetingCreatedByModel["USER"] = "User";
    MeetingCreatedByModel["DEVICE"] = "Device";
})(MeetingCreatedByModel || (MeetingCreatedByModel = {}));
export var MeetingStatus;
(function (MeetingStatus) {
    MeetingStatus["ENABLED"] = "enabled";
    MeetingStatus["DISABLED"] = "disabled";
})(MeetingStatus || (MeetingStatus = {}));
export var MeetingPrivacy;
(function (MeetingPrivacy) {
    MeetingPrivacy["PUBLIC"] = "public";
    MeetingPrivacy["PRIVATE"] = "private";
    MeetingPrivacy["CLOSED"] = "closed";
})(MeetingPrivacy || (MeetingPrivacy = {}));
export var MeetingSessionRole;
(function (MeetingSessionRole) {
    MeetingSessionRole["SPECTATOR"] = "spectator";
    MeetingSessionRole["NORMAL"] = "normal";
    MeetingSessionRole["MODERATOR"] = "moderator";
    MeetingSessionRole["ADMINISTRATOR"] = "administrator";
    MeetingSessionRole["SUPERSPECTATOR"] = "superspectator";
    MeetingSessionRole["SUPERADMINISTRATOR"] = "superadministrator";
})(MeetingSessionRole || (MeetingSessionRole = {}));
export var MeetingActorTargetModel;
(function (MeetingActorTargetModel) {
    MeetingActorTargetModel["USER"] = "User";
    MeetingActorTargetModel["DEVICE"] = "Device";
    MeetingActorTargetModel["ROOM"] = "Room";
})(MeetingActorTargetModel || (MeetingActorTargetModel = {}));
export var MeetingSessionTargetModel;
(function (MeetingSessionTargetModel) {
    MeetingSessionTargetModel["USER"] = "User";
    MeetingSessionTargetModel["DEVICE"] = "Device";
})(MeetingSessionTargetModel || (MeetingSessionTargetModel = {}));
export var MeetingSessionStatus;
(function (MeetingSessionStatus) {
    MeetingSessionStatus["RUNNING"] = "running";
    MeetingSessionStatus["FINISHED"] = "finished";
    MeetingSessionStatus["EXCLUDED"] = "excluded";
})(MeetingSessionStatus || (MeetingSessionStatus = {}));
export var MeetingSessionDeviceType;
(function (MeetingSessionDeviceType) {
    /** @see DeviceType */
    MeetingSessionDeviceType["KAST"] = "kast";
    /** Else */
    MeetingSessionDeviceType["WEB"] = "web";
    MeetingSessionDeviceType["UNKNWON"] = "unknwon";
})(MeetingSessionDeviceType || (MeetingSessionDeviceType = {}));
export var MeetingSessionMediaState;
(function (MeetingSessionMediaState) {
    MeetingSessionMediaState["WAITING"] = "waiting";
    MeetingSessionMediaState["NO_AUDIO_TRACK"] = "no_audio_track";
    MeetingSessionMediaState["NO_VIDEO_TRACK"] = "no_video_track";
    MeetingSessionMediaState["NO_TRACKS"] = "no_tracks";
    MeetingSessionMediaState["CAPTURING"] = "capturing";
    MeetingSessionMediaState["PUBLISHING"] = "publishing";
    MeetingSessionMediaState["READY"] = "ready";
    MeetingSessionMediaState["STOPPED"] = "stopped";
    MeetingSessionMediaState["ERROR"] = "error";
})(MeetingSessionMediaState || (MeetingSessionMediaState = {}));
export var MeetingSessionReaction;
(function (MeetingSessionReaction) {
    MeetingSessionReaction["RAISED_HAND"] = "raised_hand";
    MeetingSessionReaction["CLAP"] = "clap";
    MeetingSessionReaction["THUMBSDOWN"] = "thumbsdown";
    MeetingSessionReaction["THUMBSUP"] = "thumbsup";
    MeetingSessionReaction["VALIDATED"] = "validated";
    MeetingSessionReaction["UNVALIDATED"] = "unvalidated";
    MeetingSessionReaction["OK"] = "ok";
    MeetingSessionReaction["TIMEOUT"] = "timeout";
})(MeetingSessionReaction || (MeetingSessionReaction = {}));
// ------------------------------- //
// -------- Software Version -------- // 
// ------------------------------- //
export var SoftwareVersionImageType;
(function (SoftwareVersionImageType) {
    SoftwareVersionImageType["OS"] = "OS";
    SoftwareVersionImageType["APK"] = "APK";
})(SoftwareVersionImageType || (SoftwareVersionImageType = {}));
export var SoftwareVersionTag;
(function (SoftwareVersionTag) {
    SoftwareVersionTag["ALPHA"] = "alpha";
    SoftwareVersionTag["BETA"] = "beta";
    SoftwareVersionTag["RC"] = "release_candidate";
    SoftwareVersionTag["RELEASE"] = "release";
})(SoftwareVersionTag || (SoftwareVersionTag = {}));
export var SoftwareVersionStatus;
(function (SoftwareVersionStatus) {
    SoftwareVersionStatus["ENABLED"] = "enabled";
    SoftwareVersionStatus["DISABLED"] = "disabled";
    SoftwareVersionStatus["HIDDEN"] = "hidden";
})(SoftwareVersionStatus || (SoftwareVersionStatus = {}));
// ------------------------------- //
// -------- VOD -------- // 
// ------------------------------- //
export var VodState;
(function (VodState) {
    VodState["Idle"] = "idle";
    VodState["Starting"] = "starting";
    VodState["RecordOngoing"] = "record_ongoing";
    VodState["RecordError"] = "record_error";
    VodState["RecordStopWaitingDevice"] = "record_stop_waiting_device";
    VodState["RecordStopError"] = "record_stop_error";
    VodState["UploadStartWaitingDevice"] = "upload_start_waiting_device";
    VodState["UploadStartError"] = "upload_start_error";
    VodState["UploadOngoing"] = "upload_ongoing";
    VodState["UploadError"] = "upload_error";
    VodState["PublishStartWaitingService"] = "publish_start_waiting_service";
    VodState["PublishStartError"] = "publish_start_error";
    VodState["PublishOngoing"] = "publish_ongoing";
    VodState["PublishError"] = "publish_error";
    VodState["Terminated"] = "terminated";
})(VodState || (VodState = {}));
// ------------------------------- //
// -------- WEBHOOKS -------- // 
// ------------------------------- //
export var Webhook;
(function (Webhook) {
    // ------------ USER ----------- //
    Webhook["USER_MODEL_CREATED"] = "user_model_created";
    Webhook["USER_MODEL_UPDATED"] = "user_model_updated";
    Webhook["USER_MODEL_DELETED"] = "user_model_deleted";
    // ------------ ROOM ----------- //
    Webhook["ROOM_MODEL_CREATED"] = "room_model_created";
    Webhook["ROOM_MODEL_UPDATED"] = "room_model_updated";
    Webhook["ROOM_MODEL_DELETED"] = "room_model_deleted";
    // ------------ DEVICE ----------- //
    Webhook["DEVICE_MODEL_CREATED"] = "device_model_created";
    Webhook["DEVICE_MODEL_UPDATED"] = "device_model_updated";
    Webhook["DEVICE_MODEL_DELETED"] = "device_model_deleted";
    Webhook["DEVICE_LIVE_START"] = "device_live_start";
    Webhook["DEVICE_LIVE_STOP"] = "device_live_stop";
    Webhook["DEVICE_LIVE_FAILURE"] = "device_live_failure";
    Webhook["DEVICE_RECORD_START"] = "device_record_start";
    Webhook["DEVICE_RECORD_STOP"] = "device_record_stop";
    Webhook["DEVICE_RECORD_FAILURE"] = "device_record_failure";
    Webhook["DEVICE_STATUS_CHANGE"] = "device_status_change";
    Webhook["DEVICE_CONNECTION_CHANGE"] = "device_connection_change";
    // ------------ ACTIVITY ----------- //
    Webhook["ACTIVITY_MODEL_CREATED"] = "activity_model_created";
    Webhook["ACTIVITY_MODEL_UPDATED"] = "activity_model_updated";
    Webhook["ACTIVITY_MODEL_DELETED"] = "activity_model_deleted";
    Webhook["ACTIVITY_LIVE_START"] = "activity_live_start";
    Webhook["ACTIVITY_LIVE_STOP"] = "activity_live_stop";
    Webhook["ACTIVITY_LIVE_FAILURE"] = "activity_live_failure";
    Webhook["ACTIVITY_VOD_START_RECORD"] = "activity_vod_start_record";
    Webhook["ACTIVITY_VOD_STOP_RECORD"] = "activity_vod_stop_record";
    Webhook["ACTIVITY_VOD_FAILURE"] = "activity_vod_failure";
    Webhook["ACTIVITY_VOD_READY"] = "activity_vod_ready";
    Webhook["ACTIVITY_USER_JOIN"] = "activity_user_join";
    Webhook["ACTIVITY_USER_LEAVE"] = "activity_user_leave";
    Webhook["ACTIVITY_MESSAGE_NEW"] = "activity_message_new";
    Webhook["ACTIVITY_MESSAGE_REACTION"] = "activity_message_reaction";
})(Webhook || (Webhook = {}));
export var WhiteboardTargetModels;
(function (WhiteboardTargetModels) {
    WhiteboardTargetModels["Meeting"] = "Meeting";
    WhiteboardTargetModels["Activity"] = "Activity";
})(WhiteboardTargetModels || (WhiteboardTargetModels = {}));
