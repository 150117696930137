import { SocketActionType } from "../../constants";
function get(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY, payload, options);
}
function getState(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_STATE, payload, options);
}
// ------------------------------- //
// ------------ User ------------ // 
// ------------------------------- //
function join(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_JOIN, payload, options);
}
function leave(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_LEAVE, payload, options);
}
function getUsers(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_USERS, payload, options);
}
// ------------------------------- //
// ------------ Message ------------ // 
// ------------------------------- //
function addMessage(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_MESSAGE, payload, options);
}
function addMessageReaction(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_MESSAGE_REACTION, payload, options);
}
function getMessages(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_MESSAGES, payload, options);
}
// ------------------------------- //
// ------------ Device ------------ // 
// ------------------------------- //
function getDevices(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_DEVICES, payload, options);
}
// ------------------------------- //
// ------------ PUBLISH ------------ // 
// ------------------------------- //
function startPublish(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_PUBLISH_START, payload, options);
}
function stopPublish(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_PUBLISH_STOP, payload, options);
}
// ------------------------------- //
// ------------ INTERACTIONS ------------ // 
// ------------------------------- //
function getInteractions(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_INTERACTIONS, payload, options);
}
function enableInteractions(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_INTERACTIONS_ENABLE, payload, options);
}
function disableInteractions(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_INTERACTIONS_DISABLE, payload, options);
}
function interaction_request(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_INTERACTION_REQUEST, payload, options);
}
function interaction_acceptRequest(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_INTERACTION_ACCEPT_REQUEST, payload, options);
}
function interaction_declineRequest(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_INTERACTION_DECLINE_REQUEST, payload, options);
}
function interaction_join(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_INTERACTION_JOIN, payload, options);
}
function interaction_stop(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_INTERACTION_STOP, payload, options);
}
function interaction_cancel(payload, options) {
    return this.emitMessage(SocketActionType.ACTIVITY_INTERACTION_CANCEL, payload, options);
}
var activityCommands = {
    get: get,
    getState: getState,
    // User
    join: join,
    leave: leave,
    getUsers: getUsers,
    // Mesage
    addMessage: addMessage,
    addMessageReaction: addMessageReaction,
    getMessages: getMessages,
    // Device
    getDevices: getDevices,
    // Publish
    startPublish: startPublish,
    stopPublish: stopPublish,
    // Interactions
    getInteractions: getInteractions,
    enableInteractions: enableInteractions,
    disableInteractions: disableInteractions,
    interaction: {
        request: interaction_request,
        acceptRequest: interaction_acceptRequest,
        declineRequest: interaction_declineRequest,
        join: interaction_join,
        stop: interaction_stop,
        cancel: interaction_cancel,
    },
};
export default activityCommands;
