// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/Signika.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/HalisR.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'Signika';\n  src: local('Signika'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n@font-face {\n  font-family: 'HalisR';\n  src: local('HalisR'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n}\n\n/* To get color name use this site: https://chir.ag/projects/name-that-color/#FFFFFF */\n/* Variables */\n:root {\n  /* COLORS */\n  --color-main-white : #FFFFFF;\n  --color-main-green : #17BF87;\n  --color-main-purpule : #5B41F0;\n  --color-main-black : #000000;\n  --color-main-red : #FF0000;\n\n  --color-mirage : #161E2A;\n  --color-galerry : #EBEBEB;\n\n  --color-red : #ED0C0C;\n  --color-delete-red : #FF0E0E;\n  --color-torch-red : #FF0E43;\n\n  --color-broom : #FFEB20;\n\n  --color-tree-poppy : #FF9820;\n  \n  --color-clay-ebony : #253144;\n  --color-clay-ebony-medium : #222D3F;\n  --color-clay-ebony-dark : #202A3A;\n\n  --color-blue-bayoux: #505e75;\n  --color-pickled-bluewood : #2A374D;\n  --color-pickled-bluewood-dark : #263348;\n  --color-blue-prussian : #00245A;\n  --color-blue-oxford : #384354;\n  --color-blue-dodger : #00BAFF;\n  --color-blue-cornflower : #7057FF;\n  --color-blue-cornflower-transparent : #7057ff91;\n\n  --color-grey-santas : #9C99B1;\n  --color-grey-athens : #F8F8F9;\n  --color-grey-dusty : #979797;\n  --color-grey-cod : #111111;\n  --color-grey-silver-chalice : #ABABAB;\n\n  --color-purpule-heart : #523BD1;\n\n  --color-mountain-meadow : #17bf88;\n  --color-salem : #0C835C;\n\n  --color-rose-old : #C69A7E;\n  --color-clam-shell : #DBC5B7;\n\n  /* FONTS */\n  --font-signika : Signika;\n  --font-halisR : HalisR;\n}", ""]);
// Exports
module.exports = exports;
