import { SocketActionType } from "../../constants";
// ------------------------------------ //
// ------------ WHITEBOARD ------------ // 
// ------------------------------------ //
function broadcastPages(payload, options) {
    return this.emitMessage(SocketActionType.WHITEBOARD_BROADCAST_PAGES, payload, options);
}
function broadcastCursor(payload, options) {
    return this.emitMessage(SocketActionType.WHITEBOARD_BROADCAST_CURSOR, payload, options);
}
function updateMaster(payload, options) {
    return this.emitMessage(SocketActionType.WHITEBOARD_BROADCAST_MASTER_ORDER, payload, options);
}
function sync(payload, options) {
    return this.emitMessage(SocketActionType.WHITEBOARD_SYNCHRONIZE, payload, options);
}
function join(payload, options) {
    return this.emitMessage(SocketActionType.WHITEBOARD_JOIN, payload, options);
}
function joinPage(payload, options) {
    return this.emitMessage(SocketActionType.WHITEBOARD_JOIN_PAGE, payload, options);
}
function focusPage(payload, options) {
    return this.emitMessage(SocketActionType.WHITEBOARD_FOCUS_PAGE, payload, options);
}
var whiteboardCommands = {
    broadcastPages: broadcastPages,
    broadcastCursor: broadcastCursor,
    updateMaster: updateMaster,
    sync: sync,
    join: join,
    joinPage: joinPage,
    focusPage: focusPage,
};
export default whiteboardCommands;
