import { __assign, __awaiter, __extends, __generator } from "tslib";
import { KalyzeeSessionMode } from "./constant";
import { defaultKalyzeeWebRTCSessionOptions, KalyzeeBaseWebRTCSession } from "./webrtc";
;
export var defaultKalyzeeEmitterWebRTCSessionOptions = __assign({}, defaultKalyzeeWebRTCSessionOptions);
var KalyzeeEmitterWebRTCSession = /** @class */ (function (_super) {
    __extends(KalyzeeEmitterWebRTCSession, _super);
    function KalyzeeEmitterWebRTCSession(options) {
        return _super.call(this, KalyzeeSessionMode.SENDONLY, __assign(__assign({}, defaultKalyzeeEmitterWebRTCSessionOptions), (options !== null && options !== void 0 ? options : {}))) || this;
    }
    // Override but no changes for the moment
    KalyzeeEmitterWebRTCSession.prototype.start = function (src, stream) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.startSession.call(this, src, stream)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    KalyzeeEmitterWebRTCSession.prototype.destroy = function (reason) {
        this.close(reason);
        this.removeAllEventListeners();
    };
    return KalyzeeEmitterWebRTCSession;
}(KalyzeeBaseWebRTCSession));
export { KalyzeeEmitterWebRTCSession };
