var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SocketActionType } from "../../constants";
import { SocketActionHandler } from "../../handler";
var ActivityGetActionHandler = /** @class */ (function (_super) {
    __extends(ActivityGetActionHandler, _super);
    function ActivityGetActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY) || this;
    }
    return ActivityGetActionHandler;
}(SocketActionHandler));
export { ActivityGetActionHandler };
var ActivityJoinActionHandler = /** @class */ (function (_super) {
    __extends(ActivityJoinActionHandler, _super);
    function ActivityJoinActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_JOIN) || this;
    }
    return ActivityJoinActionHandler;
}(SocketActionHandler));
export { ActivityJoinActionHandler };
var ActivityLeaveActionHandler = /** @class */ (function (_super) {
    __extends(ActivityLeaveActionHandler, _super);
    function ActivityLeaveActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_LEAVE) || this;
    }
    return ActivityLeaveActionHandler;
}(SocketActionHandler));
export { ActivityLeaveActionHandler };
var ActivityGetStateActionHandler = /** @class */ (function (_super) {
    __extends(ActivityGetStateActionHandler, _super);
    function ActivityGetStateActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_STATE) || this;
    }
    return ActivityGetStateActionHandler;
}(SocketActionHandler));
export { ActivityGetStateActionHandler };
var ActivityGetUsersActionHandler = /** @class */ (function (_super) {
    __extends(ActivityGetUsersActionHandler, _super);
    function ActivityGetUsersActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_USERS) || this;
    }
    return ActivityGetUsersActionHandler;
}(SocketActionHandler));
export { ActivityGetUsersActionHandler };
var ActivityOnJoinActionHandler = /** @class */ (function (_super) {
    __extends(ActivityOnJoinActionHandler, _super);
    function ActivityOnJoinActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_USER_JOIN) || this;
    }
    return ActivityOnJoinActionHandler;
}(SocketActionHandler));
export { ActivityOnJoinActionHandler };
var ActivityOnLeaveActionHandler = /** @class */ (function (_super) {
    __extends(ActivityOnLeaveActionHandler, _super);
    function ActivityOnLeaveActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_USER_LEAVE) || this;
    }
    return ActivityOnLeaveActionHandler;
}(SocketActionHandler));
export { ActivityOnLeaveActionHandler };
var ActivityAddMessageActionHandler = /** @class */ (function (_super) {
    __extends(ActivityAddMessageActionHandler, _super);
    function ActivityAddMessageActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_MESSAGE) || this;
    }
    return ActivityAddMessageActionHandler;
}(SocketActionHandler));
export { ActivityAddMessageActionHandler };
var ActivityAddMessageReactionActionHandler = /** @class */ (function (_super) {
    __extends(ActivityAddMessageReactionActionHandler, _super);
    function ActivityAddMessageReactionActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_MESSAGE_REACTION) || this;
    }
    return ActivityAddMessageReactionActionHandler;
}(SocketActionHandler));
export { ActivityAddMessageReactionActionHandler };
var ActivityGetMessagesActionHandler = /** @class */ (function (_super) {
    __extends(ActivityGetMessagesActionHandler, _super);
    function ActivityGetMessagesActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_MESSAGES) || this;
    }
    return ActivityGetMessagesActionHandler;
}(SocketActionHandler));
export { ActivityGetMessagesActionHandler };
var ActivityGetDevicesActionHandler = /** @class */ (function (_super) {
    __extends(ActivityGetDevicesActionHandler, _super);
    function ActivityGetDevicesActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_DEVICES) || this;
    }
    return ActivityGetDevicesActionHandler;
}(SocketActionHandler));
export { ActivityGetDevicesActionHandler };
var ActivityGetInteractionsActionHandler = /** @class */ (function (_super) {
    __extends(ActivityGetInteractionsActionHandler, _super);
    function ActivityGetInteractionsActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTIONS) || this;
    }
    return ActivityGetInteractionsActionHandler;
}(SocketActionHandler));
export { ActivityGetInteractionsActionHandler };
var ActivityEnableInteractionsActionHandler = /** @class */ (function (_super) {
    __extends(ActivityEnableInteractionsActionHandler, _super);
    function ActivityEnableInteractionsActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTIONS_ENABLE) || this;
    }
    return ActivityEnableInteractionsActionHandler;
}(SocketActionHandler));
export { ActivityEnableInteractionsActionHandler };
var ActivityDisableInteractionsActionHandler = /** @class */ (function (_super) {
    __extends(ActivityDisableInteractionsActionHandler, _super);
    function ActivityDisableInteractionsActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTIONS_DISABLE) || this;
    }
    return ActivityDisableInteractionsActionHandler;
}(SocketActionHandler));
export { ActivityDisableInteractionsActionHandler };
var ActivityInteractionRequestActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionRequestActionHandler, _super);
    function ActivityInteractionRequestActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_REQUEST) || this;
    }
    return ActivityInteractionRequestActionHandler;
}(SocketActionHandler));
export { ActivityInteractionRequestActionHandler };
var ActivityInteractionAcceptRequestActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionAcceptRequestActionHandler, _super);
    function ActivityInteractionAcceptRequestActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ACCEPT_REQUEST) || this;
    }
    return ActivityInteractionAcceptRequestActionHandler;
}(SocketActionHandler));
export { ActivityInteractionAcceptRequestActionHandler };
var ActivityInteractionDeclineRequestActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionDeclineRequestActionHandler, _super);
    function ActivityInteractionDeclineRequestActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_DECLINE_REQUEST) || this;
    }
    return ActivityInteractionDeclineRequestActionHandler;
}(SocketActionHandler));
export { ActivityInteractionDeclineRequestActionHandler };
var ActivityInteractionJoinActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionJoinActionHandler, _super);
    function ActivityInteractionJoinActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_JOIN) || this;
    }
    return ActivityInteractionJoinActionHandler;
}(SocketActionHandler));
export { ActivityInteractionJoinActionHandler };
var ActivityInteractionStopActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionStopActionHandler, _super);
    function ActivityInteractionStopActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_STOP) || this;
    }
    return ActivityInteractionStopActionHandler;
}(SocketActionHandler));
export { ActivityInteractionStopActionHandler };
var ActivityInteractionCancelActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionCancelActionHandler, _super);
    function ActivityInteractionCancelActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_CANCEL) || this;
    }
    return ActivityInteractionCancelActionHandler;
}(SocketActionHandler));
export { ActivityInteractionCancelActionHandler };
var ActivityOnInteractionActionHandler = /** @class */ (function (_super) {
    __extends(ActivityOnInteractionActionHandler, _super);
    function ActivityOnInteractionActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ON_INTERACTION) || this;
    }
    return ActivityOnInteractionActionHandler;
}(SocketActionHandler));
export { ActivityOnInteractionActionHandler };
var ActivityInteractionOnMediaRequestActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionOnMediaRequestActionHandler, _super);
    function ActivityInteractionOnMediaRequestActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ON_MEDIA_REQUEST) || this;
    }
    return ActivityInteractionOnMediaRequestActionHandler;
}(SocketActionHandler));
export { ActivityInteractionOnMediaRequestActionHandler };
var ActivityInteractionOnMediaIsReadyActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionOnMediaIsReadyActionHandler, _super);
    function ActivityInteractionOnMediaIsReadyActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ON_MEDIA_IS_READY) || this;
    }
    return ActivityInteractionOnMediaIsReadyActionHandler;
}(SocketActionHandler));
export { ActivityInteractionOnMediaIsReadyActionHandler };
var ActivityInteractionOnRequestActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionOnRequestActionHandler, _super);
    function ActivityInteractionOnRequestActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ON_REQUEST) || this;
    }
    return ActivityInteractionOnRequestActionHandler;
}(SocketActionHandler));
export { ActivityInteractionOnRequestActionHandler };
var ActivityInteractionOnAcceptRequestActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionOnAcceptRequestActionHandler, _super);
    function ActivityInteractionOnAcceptRequestActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ON_ACCEPT_REQUEST) || this;
    }
    return ActivityInteractionOnAcceptRequestActionHandler;
}(SocketActionHandler));
export { ActivityInteractionOnAcceptRequestActionHandler };
var ActivityInteractionOnDeclineRequestActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionOnDeclineRequestActionHandler, _super);
    function ActivityInteractionOnDeclineRequestActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ON_DECLINE_REQUEST) || this;
    }
    return ActivityInteractionOnDeclineRequestActionHandler;
}(SocketActionHandler));
export { ActivityInteractionOnDeclineRequestActionHandler };
var ActivityInteractionOnJoinActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionOnJoinActionHandler, _super);
    function ActivityInteractionOnJoinActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ON_JOIN) || this;
    }
    return ActivityInteractionOnJoinActionHandler;
}(SocketActionHandler));
export { ActivityInteractionOnJoinActionHandler };
var ActivityInteractionOnStopActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionOnStopActionHandler, _super);
    function ActivityInteractionOnStopActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ON_STOP) || this;
    }
    return ActivityInteractionOnStopActionHandler;
}(SocketActionHandler));
export { ActivityInteractionOnStopActionHandler };
var ActivityInteractionOnCancelActionHandler = /** @class */ (function (_super) {
    __extends(ActivityInteractionOnCancelActionHandler, _super);
    function ActivityInteractionOnCancelActionHandler() {
        return _super.call(this, SocketActionType.ACTIVITY_INTERACTION_ON_CANCEL) || this;
    }
    return ActivityInteractionOnCancelActionHandler;
}(SocketActionHandler));
export { ActivityInteractionOnCancelActionHandler };
