export var EventKill = "kill";
export var EventResponseMessage = "response_message";
export var SocketMessageStatus;
(function (SocketMessageStatus) {
    SocketMessageStatus["SUCCESS"] = "SUCCESS";
    SocketMessageStatus["ERROR"] = "ERROR";
})(SocketMessageStatus || (SocketMessageStatus = {}));
export var WebSocketChannel;
(function (WebSocketChannel) {
    WebSocketChannel["MESSAGE"] = "kast_message";
    WebSocketChannel["ERROR"] = "error";
})(WebSocketChannel || (WebSocketChannel = {}));
export var SocketActionType;
(function (SocketActionType) {
    // ACTIVITY
    SocketActionType["ACTIVITY"] = "activity";
    SocketActionType["ACTIVITY_STATE"] = "activity/state";
    SocketActionType["ACTIVITY_JOIN"] = "activity/join";
    SocketActionType["ACTIVITY_LEAVE"] = "activity/leave";
    SocketActionType["ACTIVITY_USER_JOIN"] = "activity/user_join";
    SocketActionType["ACTIVITY_USER_LEAVE"] = "activity/user_leave";
    SocketActionType["ACTIVITY_USERS"] = "activity/users";
    SocketActionType["ACTIVITY_MESSAGE"] = "activity/message";
    SocketActionType["ACTIVITY_MESSAGE_REACTION"] = "activity/message_reaction";
    SocketActionType["ACTIVITY_MESSAGES"] = "activity/messages";
    SocketActionType["ACTIVITY_DEVICES"] = "activity/devices";
    SocketActionType["ACTIVITY_PUBLISH_START"] = "activity_publish/start";
    SocketActionType["ACTIVITY_PUBLISH_STOP"] = "activity_publish/stop";
    // ACTIVITY - INTERACTION
    SocketActionType["ACTIVITY_INTERACTIONS"] = "activity/interactions";
    SocketActionType["ACTIVITY_INTERACTIONS_ENABLE"] = "activity/interactions/enable";
    SocketActionType["ACTIVITY_INTERACTIONS_DISABLE"] = "activity/interactions/disable";
    SocketActionType["ACTIVITY_INTERACTION_ON_INTERACTION"] = "activity/interaction/on_interaction";
    SocketActionType["ACTIVITY_INTERACTION_ON_MEDIA_REQUEST"] = "activity/interaction/on_media_request";
    SocketActionType["ACTIVITY_INTERACTION_ON_MEDIA_IS_READY"] = "activity/interaction/on_media_is_ready";
    SocketActionType["ACTIVITY_INTERACTION_ON_REQUEST"] = "activity/interaction/on_request";
    SocketActionType["ACTIVITY_INTERACTION_ON_ACCEPT_REQUEST"] = "activity/interaction/on_accept_request";
    SocketActionType["ACTIVITY_INTERACTION_ON_DECLINE_REQUEST"] = "activity/interaction/on_decline_request";
    SocketActionType["ACTIVITY_INTERACTION_ON_JOIN"] = "activity/interaction/on_join";
    SocketActionType["ACTIVITY_INTERACTION_ON_STOP"] = "activity/interaction/on_stop";
    SocketActionType["ACTIVITY_INTERACTION_ON_CANCEL"] = "activity/interaction/on_cancel";
    SocketActionType["ACTIVITY_INTERACTION_REQUEST"] = "activity/interaction/request";
    SocketActionType["ACTIVITY_INTERACTION_ACCEPT_REQUEST"] = "activity/interaction/accept_request";
    SocketActionType["ACTIVITY_INTERACTION_DECLINE_REQUEST"] = "activity/interaction/decline_request";
    SocketActionType["ACTIVITY_INTERACTION_JOIN"] = "activity/interaction/join";
    SocketActionType["ACTIVITY_INTERACTION_STOP"] = "activity/interaction/stop";
    SocketActionType["ACTIVITY_INTERACTION_CANCEL"] = "activity/interaction/cancel";
    // MEETING
    SocketActionType["MEETING_GET_BY_ID"] = "meeting/get_by_id";
    SocketActionType["MEETING_UPDATE_BY_ID"] = "meeting/update_by_id";
    SocketActionType["MEETING_JOIN"] = "meeting/join";
    SocketActionType["MEETING_LEAVE"] = "meeting/leave";
    SocketActionType["MEETING_EXCLUDE"] = "meeting/exclude";
    SocketActionType["MEETING_ON_JOIN"] = "meeting/on_join";
    SocketActionType["MEETING_ON_LEAVE"] = "meeting/on_leave";
    SocketActionType["MEETING_ON_CHANGE"] = "meeting/on_change";
    SocketActionType["MEETING_ON_EXCLUDE"] = "meeting/on_exclude";
    // MEETING - SESSION
    SocketActionType["MEETING_SESSION_STATS"] = "meeting/session/stats";
    SocketActionType["MEETING_SESSION_UPDATE"] = "meeting/session/update";
    SocketActionType["MEETING_SESSION_CUSTOM_EVENT"] = "meeting/session/custom_event";
    SocketActionType["MEETING_SESSION_ON_STATS"] = "meeting/session/on_stats";
    SocketActionType["MEETING_SESSION_ON_CHANGE"] = "meeting/session/on_change";
    SocketActionType["MEETING_SESSION_ON_CUSTOM_EVENT"] = "meeting/session/on_custom_event";
    // MEETING - SESSION - MEDIA
    SocketActionType["MEETING_SESSION_MEDIA_UPDATE"] = "meeting/session/media/update";
    SocketActionType["MEETING_SESSION_MEDIA_ON_CHANGE"] = "meeting/session/media/on_change";
    // WHITEBOARD
    SocketActionType["WHITEBOARD_BROADCAST_PAGES"] = "whiteboard/broadcast_pages";
    SocketActionType["WHITEBOARD_BROADCAST_CURSOR"] = "whiteboard/broadcast_cursor";
    SocketActionType["WHITEBOARD_BROADCAST_MASTER_ORDER"] = "whiteboard/broadcast_master_order";
    SocketActionType["WHITEBOARD_SYNCHRONIZE"] = "whiteboard/synchronize";
    SocketActionType["WHITEBOARD_JOIN"] = "whiteboard/join";
    SocketActionType["WHITEBOARD_JOIN_PAGE"] = "whiteboard/join_page";
    SocketActionType["WHITEBOARD_FOCUS_PAGE"] = "whiteboard/focus_page";
    SocketActionType["WHITEBOARD_ON_INIT"] = "whiteboard/on_init";
    SocketActionType["WHITEBOARD_ON_BROADCAST_PAGES"] = "whiteboard/on_broadcast_pages";
    SocketActionType["WHITEBOARD_ON_BROADCAST_CURSOR"] = "whiteboard/on_broadcast_cursor";
    SocketActionType["WHITEBOARD_ON_BROADCAST_MASTER_ORDER"] = "whiteboard/on_broadcast_master_order";
    SocketActionType["WHITEBOARD_ON_USER_LEFT"] = "whiteboard/on_user_left";
    SocketActionType["WHITEBOARD_ON_USER_JOINED"] = "whiteboard/on_user_joined";
    SocketActionType["WHITEBOARD_ON_USER_JOINED_PAGE"] = "whiteboard/on_user_joined_page";
    SocketActionType["WHITEBOARD_ON_USER_FOCUSED_PAGE"] = "whiteboard/on_user_focused_page";
    SocketActionType["WHITEBOARD_ON_KICK"] = "whiteboard/on_kick";
    // ORGANIZATION
    SocketActionType["ORGANIZATION_DEVICES"] = "organization/devices";
    SocketActionType["ORGANIZATION_DEVICES_UPDATE_SESSIONS"] = "organization/devices/update/get_sessions";
    SocketActionType["ORGANIZATION_DEVICES_INFOMRATION"] = "organization/devices/information";
    // VIDEO
    SocketActionType["LIVE_START"] = "live/start";
    SocketActionType["LIVE_STOP"] = "live/stop";
    SocketActionType["RECORD_START"] = "record/start";
    SocketActionType["RECORD_STOP"] = "record/stop";
    SocketActionType["RECORD_FAILURE"] = "record/failure";
    SocketActionType["VIDEO_CONTEXT"] = "video/context";
    SocketActionType["VIDEO_UPLOAD_PROGRESS"] = "video/upload_progress";
    SocketActionType["VIDEO_UPLOAD_SUCCESS"] = "video/upload_success";
    SocketActionType["VIDEO_UPLOAD_FAILURE"] = "video/upload_failure";
    // VOD
    SocketActionType["VOD_STATE_UPDATE"] = "vod/state_update";
    SocketActionType["VOD_RECOVER"] = "vod/recover";
    // CAMERA
    SocketActionType["DEVICE_MOVE"] = "device/move";
    SocketActionType["DEVICE_STOP_MOVE"] = "device/stop_move";
    SocketActionType["DEVICE_ZOOM"] = "device/zoom";
    SocketActionType["DEVICE_STOP_ZOOM"] = "device/stop_zoom";
    SocketActionType["DEVICE_SWITCH_VIEW"] = "device/switch_view";
    SocketActionType["DEVICE_SET_VIEW"] = "device/set_view";
    SocketActionType["DEVICE_SWITCH_SCENE"] = "device/switch_scene";
    SocketActionType["DEVICE_STATE_CHANGE"] = "device/state_change";
    SocketActionType["DEVICE_REBOOT"] = "device/reboot";
    SocketActionType["DEVICE_FACTORY_RESET"] = "device/factory_reset";
    SocketActionType["DEVICE_UPDATE_START_DOWNLOAD"] = "device/update/start_dowload";
    SocketActionType["DEVICE_UPDATE_INSTALL"] = "device/update/install";
    SocketActionType["DEVICE_UPDATE_SCHEDULE_SILENT"] = "device/update/schedule_silent";
    SocketActionType["DEVICE_UPDATE_ABORT"] = "device/update/abort";
    SocketActionType["DEVICE_UPDATE_ON_PROGRESS_DOWNLOAD"] = "device/update/on_progress_download";
    SocketActionType["DEVICE_UPDATE_ON_SESSION"] = "device/update/on_session";
    SocketActionType["DEVICE_UPDATE_GET_SESSIONS"] = "device/update/get_sessions";
    SocketActionType["DEVICE_INFORMATION"] = "device/information";
    SocketActionType["DEVICE_SYSTEM_SET_TIME"] = "device/system/set_time";
    SocketActionType["DEVICE_SYSTEM_SET_TIMEZONE"] = "device/system/set_timezone";
    // ROOM
    SocketActionType["ROOM_DEVICES"] = "room/devices";
    // WEBHOOK
    SocketActionType["WEBHOOKS_ON_EVENT"] = "webhooks/on_event";
    SocketActionType["WEBHOOKS_LISTEN"] = "webhooks/listen";
    SocketActionType["WEBHOOKS_UNLISTEN"] = "webhooks/unlisten";
})(SocketActionType || (SocketActionType = {}));
