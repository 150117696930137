var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SocketActionType } from "../../constants";
import { SocketActionHandler } from "../../handler";
var WhiteboardOnInitHandler = /** @class */ (function (_super) {
    __extends(WhiteboardOnInitHandler, _super);
    function WhiteboardOnInitHandler() {
        return _super.call(this, SocketActionType.WHITEBOARD_ON_INIT) || this;
    }
    return WhiteboardOnInitHandler;
}(SocketActionHandler));
export { WhiteboardOnInitHandler };
var WhiteboardOnBroadcastPagesHandler = /** @class */ (function (_super) {
    __extends(WhiteboardOnBroadcastPagesHandler, _super);
    function WhiteboardOnBroadcastPagesHandler() {
        return _super.call(this, SocketActionType.WHITEBOARD_ON_BROADCAST_PAGES) || this;
    }
    return WhiteboardOnBroadcastPagesHandler;
}(SocketActionHandler));
export { WhiteboardOnBroadcastPagesHandler };
var WhiteboardOnBroadcastCursorHandler = /** @class */ (function (_super) {
    __extends(WhiteboardOnBroadcastCursorHandler, _super);
    function WhiteboardOnBroadcastCursorHandler() {
        return _super.call(this, SocketActionType.WHITEBOARD_ON_BROADCAST_CURSOR) || this;
    }
    return WhiteboardOnBroadcastCursorHandler;
}(SocketActionHandler));
export { WhiteboardOnBroadcastCursorHandler };
var WhiteboardOnBroadcastMasterOrderHandler = /** @class */ (function (_super) {
    __extends(WhiteboardOnBroadcastMasterOrderHandler, _super);
    function WhiteboardOnBroadcastMasterOrderHandler() {
        return _super.call(this, SocketActionType.WHITEBOARD_ON_BROADCAST_MASTER_ORDER) || this;
    }
    return WhiteboardOnBroadcastMasterOrderHandler;
}(SocketActionHandler));
export { WhiteboardOnBroadcastMasterOrderHandler };
var WhiteboardOnUserLeftHandler = /** @class */ (function (_super) {
    __extends(WhiteboardOnUserLeftHandler, _super);
    function WhiteboardOnUserLeftHandler() {
        return _super.call(this, SocketActionType.WHITEBOARD_ON_USER_LEFT) || this;
    }
    return WhiteboardOnUserLeftHandler;
}(SocketActionHandler));
export { WhiteboardOnUserLeftHandler };
var WhiteboardOnUserJoinedHandler = /** @class */ (function (_super) {
    __extends(WhiteboardOnUserJoinedHandler, _super);
    function WhiteboardOnUserJoinedHandler() {
        return _super.call(this, SocketActionType.WHITEBOARD_ON_USER_JOINED) || this;
    }
    return WhiteboardOnUserJoinedHandler;
}(SocketActionHandler));
export { WhiteboardOnUserJoinedHandler };
var WhiteboardOnUserJoinedPageHandler = /** @class */ (function (_super) {
    __extends(WhiteboardOnUserJoinedPageHandler, _super);
    function WhiteboardOnUserJoinedPageHandler() {
        return _super.call(this, SocketActionType.WHITEBOARD_ON_USER_JOINED_PAGE) || this;
    }
    return WhiteboardOnUserJoinedPageHandler;
}(SocketActionHandler));
export { WhiteboardOnUserJoinedPageHandler };
var WhiteboardOnUserFocusedPageHandler = /** @class */ (function (_super) {
    __extends(WhiteboardOnUserFocusedPageHandler, _super);
    function WhiteboardOnUserFocusedPageHandler() {
        return _super.call(this, SocketActionType.WHITEBOARD_ON_USER_FOCUSED_PAGE) || this;
    }
    return WhiteboardOnUserFocusedPageHandler;
}(SocketActionHandler));
export { WhiteboardOnUserFocusedPageHandler };
var WhiteboardOnKickHandler = /** @class */ (function (_super) {
    __extends(WhiteboardOnKickHandler, _super);
    function WhiteboardOnKickHandler() {
        return _super.call(this, SocketActionType.WHITEBOARD_ON_KICK) || this;
    }
    return WhiteboardOnKickHandler;
}(SocketActionHandler));
export { WhiteboardOnKickHandler };
