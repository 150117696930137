export var ERROR_MESSAGE_SERVER_TIMEOUT = {
    name: "Command timeout",
    message: "Server doesn't respond to this command.",
    stack: "",
    code: 1,
    cname: "COMMAND_TIMEOUT",
    timeout: true,
};
export var isTimeout = function (error) {
    if (!error)
        return false;
    if (error.code === 1 || error.code === -ApiErrorCode.SOCKET_MESSAGE_RESPONSE_TIMEOUT) {
        return true;
    }
    return false;
};
export var ConnectionError;
(function (ConnectionError) {
    ConnectionError[ConnectionError["UNKNOW"] = 0] = "UNKNOW";
    ConnectionError[ConnectionError["NETWORK"] = 1] = "NETWORK";
    ConnectionError[ConnectionError["UNAUTHORIZED"] = 2] = "UNAUTHORIZED";
    ConnectionError[ConnectionError["TOKEN_EXPIRED"] = 3] = "TOKEN_EXPIRED";
    ConnectionError[ConnectionError["SOCKET"] = 4] = "SOCKET";
    ConnectionError[ConnectionError["SOCKET_UNAUTHORIZED"] = 5] = "SOCKET_UNAUTHORIZED";
})(ConnectionError || (ConnectionError = {}));
export var ApiErrorSection;
(function (ApiErrorSection) {
    ApiErrorSection[ApiErrorSection["DEFAULT"] = 0] = "DEFAULT";
    ApiErrorSection[ApiErrorSection["TOKEN"] = 50] = "TOKEN";
    ApiErrorSection[ApiErrorSection["USER"] = 100] = "USER";
    ApiErrorSection[ApiErrorSection["ORGANIZATION"] = 150] = "ORGANIZATION";
    ApiErrorSection[ApiErrorSection["ACTIVITY"] = 200] = "ACTIVITY";
    ApiErrorSection[ApiErrorSection["DEVICE"] = 250] = "DEVICE";
    ApiErrorSection[ApiErrorSection["ROOM"] = 300] = "ROOM";
    ApiErrorSection[ApiErrorSection["CHATROOM"] = 350] = "CHATROOM";
    ApiErrorSection[ApiErrorSection["MESSAGE"] = 400] = "MESSAGE";
    ApiErrorSection[ApiErrorSection["LTI"] = 450] = "LTI";
    ApiErrorSection[ApiErrorSection["CAS"] = 500] = "CAS";
    ApiErrorSection[ApiErrorSection["DEVICE_UPLOAD"] = 550] = "DEVICE_UPLOAD";
    ApiErrorSection[ApiErrorSection["DEVICE_SESSION"] = 600] = "DEVICE_SESSION";
    ApiErrorSection[ApiErrorSection["CONTENT_LOCATION"] = 650] = "CONTENT_LOCATION";
    ApiErrorSection[ApiErrorSection["SOFTERWARE_VERSION"] = 700] = "SOFTERWARE_VERSION";
    ApiErrorSection[ApiErrorSection["IDENTITY"] = 750] = "IDENTITY";
    ApiErrorSection[ApiErrorSection["ACTIVITY_INTERACTION"] = 800] = "ACTIVITY_INTERACTION";
    ApiErrorSection[ApiErrorSection["WEBHOOKS"] = 900] = "WEBHOOKS";
    ApiErrorSection[ApiErrorSection["VOD"] = 1000] = "VOD";
    ApiErrorSection[ApiErrorSection["PUBLISH_PROFULE"] = 1100] = "PUBLISH_PROFULE";
    ApiErrorSection[ApiErrorSection["PASSWORD_RECOVERY"] = 1200] = "PASSWORD_RECOVERY";
    ApiErrorSection[ApiErrorSection["MEETING"] = 1300] = "MEETING";
    // SOCKET
    ApiErrorSection[ApiErrorSection["SOCKET_DEFAULT"] = 10000] = "SOCKET_DEFAULT";
    ApiErrorSection[ApiErrorSection["SOCKET_MESSAGE"] = 10100] = "SOCKET_MESSAGE";
})(ApiErrorSection || (ApiErrorSection = {}));
export var ApiErrorCode;
(function (ApiErrorCode) {
    // DEFAULT
    ApiErrorCode[ApiErrorCode["NO_ERRORS"] = 0] = "NO_ERRORS";
    ApiErrorCode[ApiErrorCode["NOT_FOUND"] = 1] = "NOT_FOUND";
    ApiErrorCode[ApiErrorCode["FILE_NOT_FOUND"] = 2] = "FILE_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["INTERNAL_ERROR"] = 3] = "INTERNAL_ERROR";
    ApiErrorCode[ApiErrorCode["DEFAULT_ERROR"] = 4] = "DEFAULT_ERROR";
    ApiErrorCode[ApiErrorCode["UNKNOWN_ERROR"] = 5] = "UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["PERMISSION_DENIED"] = 6] = "PERMISSION_DENIED";
    ApiErrorCode[ApiErrorCode["RESOURCE_DENIED"] = 7] = "RESOURCE_DENIED";
    ApiErrorCode[ApiErrorCode["UPDATE_RESOURCE_ERROR"] = 8] = "UPDATE_RESOURCE_ERROR";
    ApiErrorCode[ApiErrorCode["DELETE_RESOURCE_ERROR"] = 9] = "DELETE_RESOURCE_ERROR";
    ApiErrorCode[ApiErrorCode["VALIDATION_ERROR"] = 10] = "VALIDATION_ERROR";
    ApiErrorCode[ApiErrorCode["HTTP_ERROR"] = 11] = "HTTP_ERROR";
    // TOKEN
    ApiErrorCode[ApiErrorCode["TOKEN_UNKNOWN_ERROR"] = 50] = "TOKEN_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["TOKEN_NOT_FOUND"] = 51] = "TOKEN_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["TOKEN_EXPIRED"] = 52] = "TOKEN_EXPIRED";
    ApiErrorCode[ApiErrorCode["TOKEN_IS_INVALID"] = 53] = "TOKEN_IS_INVALID";
    ApiErrorCode[ApiErrorCode["REFRESH_TOKEN_IS_EXPIRED"] = 54] = "REFRESH_TOKEN_IS_EXPIRED";
    ApiErrorCode[ApiErrorCode["REFRESH_TOKEN_IS_INVALID"] = 55] = "REFRESH_TOKEN_IS_INVALID";
    // USER
    ApiErrorCode[ApiErrorCode["USER_UNKNOWN_ERROR"] = 100] = "USER_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["USER_NOT_FOUND"] = 101] = "USER_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["USER_IS_BANNED"] = 102] = "USER_IS_BANNED";
    ApiErrorCode[ApiErrorCode["USER_IS_DISABLED"] = 103] = "USER_IS_DISABLED";
    ApiErrorCode[ApiErrorCode["USER_TRIAL_PERIOD_IS_OVER"] = 104] = "USER_TRIAL_PERIOD_IS_OVER";
    // ORGANIZATION
    ApiErrorCode[ApiErrorCode["ORGANIZATION_UNKNOWN_ERROR"] = 150] = "ORGANIZATION_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["ORGANIZATION_NOT_FOUND"] = 151] = "ORGANIZATION_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["ORGANIZATION_NOT_DEFINED"] = 152] = "ORGANIZATION_NOT_DEFINED";
    ApiErrorCode[ApiErrorCode["ORGANIZATION_IS_DISABLED"] = 153] = "ORGANIZATION_IS_DISABLED";
    ApiErrorCode[ApiErrorCode["ORGANIZATION_TRIAL_PERIOD_IS_OVER"] = 154] = "ORGANIZATION_TRIAL_PERIOD_IS_OVER";
    // ACTIVITY
    ApiErrorCode[ApiErrorCode["ACTIVITY_UNKNOWN_ERROR"] = 200] = "ACTIVITY_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["ACTIVITY_NOT_FOUND"] = 201] = "ACTIVITY_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["ACTIVITY_IS_NOT_PUBLIC"] = 202] = "ACTIVITY_IS_NOT_PUBLIC";
    ApiErrorCode[ApiErrorCode["ACTIVITY_IS_CURRENTLY_PUBLISHING"] = 203] = "ACTIVITY_IS_CURRENTLY_PUBLISHING";
    ApiErrorCode[ApiErrorCode["ACTIVITY_USER_DID_NO_JOIN"] = 204] = "ACTIVITY_USER_DID_NO_JOIN";
    ApiErrorCode[ApiErrorCode["ACTIVITY_ID_UNDEFINED"] = 205] = "ACTIVITY_ID_UNDEFINED";
    // DEVICE
    ApiErrorCode[ApiErrorCode["DEVICE_UNKNOWN_ERROR"] = 250] = "DEVICE_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["DEVICE_NOT_FOUND"] = 251] = "DEVICE_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["DEVICE_ALREADY_EXISTS"] = 252] = "DEVICE_ALREADY_EXISTS";
    ApiErrorCode[ApiErrorCode["DEVICE_UPDATING_ERROR"] = 253] = "DEVICE_UPDATING_ERROR";
    ApiErrorCode[ApiErrorCode["DEVICE_IS_NOT_CONNECTED"] = 254] = "DEVICE_IS_NOT_CONNECTED";
    ApiErrorCode[ApiErrorCode["DEVICE_ID_UNDEFINED"] = 255] = "DEVICE_ID_UNDEFINED";
    // ROOM 
    ApiErrorCode[ApiErrorCode["ROOM_UNKNOWN_ERROR"] = 300] = "ROOM_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["ROOM_NOT_FOUND"] = 301] = "ROOM_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["ROOM_NOT_AVAILABLE"] = 302] = "ROOM_NOT_AVAILABLE";
    ApiErrorCode[ApiErrorCode["ROOM_ID_UNDEFINED"] = 303] = "ROOM_ID_UNDEFINED";
    // CHATROOM
    ApiErrorCode[ApiErrorCode["CHATROOM_UNKNOWN_ERROR"] = 350] = "CHATROOM_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["CHATROOM_NOT_FOUND"] = 351] = "CHATROOM_NOT_FOUND";
    // MESSAGE
    ApiErrorCode[ApiErrorCode["MESSAGE_UNKNOWN_ERROR"] = 400] = "MESSAGE_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["MESSAGE_NOT_FOUND"] = 401] = "MESSAGE_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["MESSAGE_FORMAT_ERROR"] = 402] = "MESSAGE_FORMAT_ERROR";
    ApiErrorCode[ApiErrorCode["MESSAGE_USER_NOT_AUTHORIZE_CREATE_FOR_CHATROOM"] = 403] = "MESSAGE_USER_NOT_AUTHORIZE_CREATE_FOR_CHATROOM";
    ApiErrorCode[ApiErrorCode["MESSAGE_AUTHOR_NOT_EXIST"] = 404] = "MESSAGE_AUTHOR_NOT_EXIST";
    // LTI
    ApiErrorCode[ApiErrorCode["LTI_UNKNOWN_ERROR"] = 450] = "LTI_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["LTI_NOT_FOUND"] = 451] = "LTI_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["LTI_SESSION_TIMEOUT_DEFAULT"] = 452] = "LTI_SESSION_TIMEOUT_DEFAULT";
    ApiErrorCode[ApiErrorCode["LTI_INVALID_TOKEN_DEFAULT"] = 453] = "LTI_INVALID_TOKEN_DEFAULT";
    ApiErrorCode[ApiErrorCode["LTI_INVALID_TOKEN_MISSING_COOKIE"] = 454] = "LTI_INVALID_TOKEN_MISSING_COOKIE";
    ApiErrorCode[ApiErrorCode["LTI_REDIRECTION_NOT_FOUND"] = 455] = "LTI_REDIRECTION_NOT_FOUND";
    // CAS
    ApiErrorCode[ApiErrorCode["CAS_UNKNOWN_ERROR"] = 500] = "CAS_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["CAS_NOT_FOUND"] = 501] = "CAS_NOT_FOUND";
    // SOFTWARE VERSION
    ApiErrorCode[ApiErrorCode["SOFTWARE_VERSION_UNKNOWN_ERROR"] = 700] = "SOFTWARE_VERSION_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["SOFTWARE_VERSION_ALREADY_EXIST"] = 701] = "SOFTWARE_VERSION_ALREADY_EXIST";
    // DEVICE UPLOAD
    ApiErrorCode[ApiErrorCode["DEVICE_UPLOAD_NOT_FOUND"] = 550] = "DEVICE_UPLOAD_NOT_FOUND";
    // DEVICE SESSION
    ApiErrorCode[ApiErrorCode["DEVICE_SESSION_NOT_FOUND"] = 600] = "DEVICE_SESSION_NOT_FOUND";
    // CONTENT LOCATION
    ApiErrorCode[ApiErrorCode["CONTENT_LOCATION_NOT_FOUND"] = 650] = "CONTENT_LOCATION_NOT_FOUND";
    // IDENTITY
    ApiErrorCode[ApiErrorCode["IDENTITY_NOT_FOUND"] = 750] = "IDENTITY_NOT_FOUND";
    // ACTIVITY - INTERACTION
    ApiErrorCode[ApiErrorCode["ACTIVITY_INTERACTION_UNKNOWN_ERROR"] = 800] = "ACTIVITY_INTERACTION_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["ACTIVITY_INTERACTION_NOT_FOUND"] = 801] = "ACTIVITY_INTERACTION_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["ACTIVITY_INTERACTION_ALREADY_IN_PROGRESS"] = 802] = "ACTIVITY_INTERACTION_ALREADY_IN_PROGRESS";
    ApiErrorCode[ApiErrorCode["ACTIVITY_SIMULTANEOUS_ACTIVE_INTERACTION_LIMIT_IS_EXCEEDED"] = 803] = "ACTIVITY_SIMULTANEOUS_ACTIVE_INTERACTION_LIMIT_IS_EXCEEDED";
    ApiErrorCode[ApiErrorCode["ACTIVITY_INTERACTION_EVENT_NOT_FOUND"] = 804] = "ACTIVITY_INTERACTION_EVENT_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["ACTIVITY_INTERACTION_UNAUTHORIZED"] = 805] = "ACTIVITY_INTERACTION_UNAUTHORIZED";
    ApiErrorCode[ApiErrorCode["ACTIVITY_INTERACTION_MESSAGE_TOO_LONG"] = 806] = "ACTIVITY_INTERACTION_MESSAGE_TOO_LONG";
    ApiErrorCode[ApiErrorCode["ACTIVITY_INTERACTION_NOT_ENABLED_FOR_THIS_ORGANIZATION"] = 807] = "ACTIVITY_INTERACTION_NOT_ENABLED_FOR_THIS_ORGANIZATION";
    // WEBHOOKS
    ApiErrorCode[ApiErrorCode["WEBHOOKS_UNKNOWN_ERROR"] = 900] = "WEBHOOKS_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["WEBHOOKS_NOT_FOUND"] = 901] = "WEBHOOKS_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["WEBHOOKS_DISABLED"] = 902] = "WEBHOOKS_DISABLED";
    ApiErrorCode[ApiErrorCode["WEBHOOKS_EXPIRED"] = 903] = "WEBHOOKS_EXPIRED";
    ApiErrorCode[ApiErrorCode["WEBHOOKS_OVERUSED"] = 904] = "WEBHOOKS_OVERUSED";
    ApiErrorCode[ApiErrorCode["WEBHOOKS_UNAUTHORIZE_WEBSOCKET"] = 905] = "WEBHOOKS_UNAUTHORIZE_WEBSOCKET";
    // VOD
    ApiErrorCode[ApiErrorCode["VOD_NUNKNOWN_ERROR"] = 1000] = "VOD_NUNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["VOD_NOT_FOUND"] = 1001] = "VOD_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["VOD_ALREADY_ACTIVE"] = 1002] = "VOD_ALREADY_ACTIVE";
    ApiErrorCode[ApiErrorCode["VOD_NO_ACTIVE_FOUND"] = 1003] = "VOD_NO_ACTIVE_FOUND";
    // PUBLISH PROFILE
    ApiErrorCode[ApiErrorCode["PUBLISH_PROFILE_NUNKNOWN_ERROR"] = 1100] = "PUBLISH_PROFILE_NUNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["PUBLISH_PROFILE_NOT_EXIST"] = 1101] = "PUBLISH_PROFILE_NOT_EXIST";
    ApiErrorCode[ApiErrorCode["PUBLISH_PROFILE_BAD_TYPE"] = 1102] = "PUBLISH_PROFILE_BAD_TYPE";
    ApiErrorCode[ApiErrorCode["PUBLISH_PROFILE_BAD_USER"] = 1103] = "PUBLISH_PROFILE_BAD_USER";
    ApiErrorCode[ApiErrorCode["PUBLISH_PROFILE_BAD_ROOM"] = 1104] = "PUBLISH_PROFILE_BAD_ROOM";
    // PASSWORD RECOVERY
    ApiErrorCode[ApiErrorCode["PASSWORD_RECOVERY_INVALID_USER"] = 1200] = "PASSWORD_RECOVERY_INVALID_USER";
    ApiErrorCode[ApiErrorCode["PASSWORD_RECOVERY_EMAIL_NOT_SENT"] = 1201] = "PASSWORD_RECOVERY_EMAIL_NOT_SENT";
    // SOCKET
    ApiErrorCode[ApiErrorCode["SOCKET_UNKNOWN_ERROR"] = 10000] = "SOCKET_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["SOCKET_AUTHENTICATION"] = 10001] = "SOCKET_AUTHENTICATION";
    ApiErrorCode[ApiErrorCode["SOCKET_UNAUTHORIZED"] = 10002] = "SOCKET_UNAUTHORIZED";
    ApiErrorCode[ApiErrorCode["SOCKET_ON_CONNECT"] = 10003] = "SOCKET_ON_CONNECT";
    ApiErrorCode[ApiErrorCode["SOCKET_ON_DISCONNECT"] = 10004] = "SOCKET_ON_DISCONNECT";
    ApiErrorCode[ApiErrorCode["SOCKET_DEPRECATED"] = 10005] = "SOCKET_DEPRECATED";
    // SOCKET - MESSAGE 
    ApiErrorCode[ApiErrorCode["SOCKET_MESSAGE_UNKNOWN_ERROR"] = 10100] = "SOCKET_MESSAGE_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["SOCKET_MESSAGE_MIDDLEARE"] = 10101] = "SOCKET_MESSAGE_MIDDLEARE";
    ApiErrorCode[ApiErrorCode["SOCKET_MESSAGE_FORMAT"] = 10102] = "SOCKET_MESSAGE_FORMAT";
    ApiErrorCode[ApiErrorCode["SOCKET_MESSAGE_DISPATCH"] = 10103] = "SOCKET_MESSAGE_DISPATCH";
    ApiErrorCode[ApiErrorCode["SOCKET_MESSAGE_UNAUTHORIZED"] = 10104] = "SOCKET_MESSAGE_UNAUTHORIZED";
    ApiErrorCode[ApiErrorCode["SOCKET_MESSAGE_NOT_FOUND_FROM_CORRELATION_ID"] = 10105] = "SOCKET_MESSAGE_NOT_FOUND_FROM_CORRELATION_ID";
    ApiErrorCode[ApiErrorCode["SOCKET_MESSAGE_RESPONSE_TIMEOUT"] = 10106] = "SOCKET_MESSAGE_RESPONSE_TIMEOUT";
    ApiErrorCode[ApiErrorCode["SOCKET_MESSAGE_RESPONSE_ERROR"] = 10107] = "SOCKET_MESSAGE_RESPONSE_ERROR";
    // MEETING
    ApiErrorCode[ApiErrorCode["MEETING_UNKNOWN_ERROR"] = 1300] = "MEETING_UNKNOWN_ERROR";
    ApiErrorCode[ApiErrorCode["MEETING_NOT_FOUND"] = 1301] = "MEETING_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["MEETING_IS_NOT_PUBLIC"] = 1302] = "MEETING_IS_NOT_PUBLIC";
    ApiErrorCode[ApiErrorCode["MEETING_ID_UNDEFINED"] = 1303] = "MEETING_ID_UNDEFINED";
    ApiErrorCode[ApiErrorCode["MEETING_SHORT_ID_IS_NOT_ACCEPTED"] = 1304] = "MEETING_SHORT_ID_IS_NOT_ACCEPTED";
    ApiErrorCode[ApiErrorCode["MEETING_INVALID_PASSWORD"] = 1305] = "MEETING_INVALID_PASSWORD";
    ApiErrorCode[ApiErrorCode["MEETING_PASSWORD_IS_NEEDED"] = 1306] = "MEETING_PASSWORD_IS_NEEDED";
    ApiErrorCode[ApiErrorCode["MEETING_IS_PRIVATE"] = 1307] = "MEETING_IS_PRIVATE";
    ApiErrorCode[ApiErrorCode["MEETING_IS_CLOSED"] = 1308] = "MEETING_IS_CLOSED";
    ApiErrorCode[ApiErrorCode["MEETING_LEAVE_NEVER_JOINED"] = 1309] = "MEETING_LEAVE_NEVER_JOINED";
    ApiErrorCode[ApiErrorCode["MEETING_UNAUTHORIZED_TO_UPDATE"] = 1310] = "MEETING_UNAUTHORIZED_TO_UPDATE";
    ApiErrorCode[ApiErrorCode["MEETING_ALREADY_JOINED"] = 1311] = "MEETING_ALREADY_JOINED";
    ApiErrorCode[ApiErrorCode["MEETING_ALREADY_JOINED_ANOTHER_MEETING"] = 1312] = "MEETING_ALREADY_JOINED_ANOTHER_MEETING";
    ApiErrorCode[ApiErrorCode["MEETING_SESSION_NOT_FOUND"] = 1313] = "MEETING_SESSION_NOT_FOUND";
    ApiErrorCode[ApiErrorCode["MEETING_SESSION_ID_UNDEFINED"] = 1314] = "MEETING_SESSION_ID_UNDEFINED";
    ApiErrorCode[ApiErrorCode["MEETING_NOT_JOINED"] = 1315] = "MEETING_NOT_JOINED";
})(ApiErrorCode || (ApiErrorCode = {}));
