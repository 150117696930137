var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SocketActionType } from "../../constants";
import { SocketActionHandler } from "../../handler";
var MeetingGetByIdActionHandler = /** @class */ (function (_super) {
    __extends(MeetingGetByIdActionHandler, _super);
    function MeetingGetByIdActionHandler() {
        return _super.call(this, SocketActionType.MEETING_GET_BY_ID) || this;
    }
    return MeetingGetByIdActionHandler;
}(SocketActionHandler));
export { MeetingGetByIdActionHandler };
var MeetingUpdateByIdActionHandler = /** @class */ (function (_super) {
    __extends(MeetingUpdateByIdActionHandler, _super);
    function MeetingUpdateByIdActionHandler() {
        return _super.call(this, SocketActionType.MEETING_UPDATE_BY_ID) || this;
    }
    return MeetingUpdateByIdActionHandler;
}(SocketActionHandler));
export { MeetingUpdateByIdActionHandler };
var MeetingJoinActionHandler = /** @class */ (function (_super) {
    __extends(MeetingJoinActionHandler, _super);
    function MeetingJoinActionHandler() {
        return _super.call(this, SocketActionType.MEETING_JOIN) || this;
    }
    return MeetingJoinActionHandler;
}(SocketActionHandler));
export { MeetingJoinActionHandler };
var MeetingLeaveActionHandler = /** @class */ (function (_super) {
    __extends(MeetingLeaveActionHandler, _super);
    function MeetingLeaveActionHandler() {
        return _super.call(this, SocketActionType.MEETING_LEAVE) || this;
    }
    return MeetingLeaveActionHandler;
}(SocketActionHandler));
export { MeetingLeaveActionHandler };
var MeetingExcludeActionHandler = /** @class */ (function (_super) {
    __extends(MeetingExcludeActionHandler, _super);
    function MeetingExcludeActionHandler() {
        return _super.call(this, SocketActionType.MEETING_EXCLUDE) || this;
    }
    return MeetingExcludeActionHandler;
}(SocketActionHandler));
export { MeetingExcludeActionHandler };
var MeetingSessionUpdateActionHandler = /** @class */ (function (_super) {
    __extends(MeetingSessionUpdateActionHandler, _super);
    function MeetingSessionUpdateActionHandler() {
        return _super.call(this, SocketActionType.MEETING_SESSION_UPDATE) || this;
    }
    return MeetingSessionUpdateActionHandler;
}(SocketActionHandler));
export { MeetingSessionUpdateActionHandler };
var MeetingSessionCustomEventActionHandler = /** @class */ (function (_super) {
    __extends(MeetingSessionCustomEventActionHandler, _super);
    function MeetingSessionCustomEventActionHandler() {
        return _super.call(this, SocketActionType.MEETING_SESSION_CUSTOM_EVENT) || this;
    }
    return MeetingSessionCustomEventActionHandler;
}(SocketActionHandler));
export { MeetingSessionCustomEventActionHandler };
var MeetingSessionPushStatsActionHandler = /** @class */ (function (_super) {
    __extends(MeetingSessionPushStatsActionHandler, _super);
    function MeetingSessionPushStatsActionHandler() {
        return _super.call(this, SocketActionType.MEETING_SESSION_STATS) || this;
    }
    return MeetingSessionPushStatsActionHandler;
}(SocketActionHandler));
export { MeetingSessionPushStatsActionHandler };
var MeetingSessionMediaUpdateActionHandler = /** @class */ (function (_super) {
    __extends(MeetingSessionMediaUpdateActionHandler, _super);
    function MeetingSessionMediaUpdateActionHandler() {
        return _super.call(this, SocketActionType.MEETING_SESSION_MEDIA_UPDATE) || this;
    }
    return MeetingSessionMediaUpdateActionHandler;
}(SocketActionHandler));
export { MeetingSessionMediaUpdateActionHandler };
var MeetingOnJoinActionHandler = /** @class */ (function (_super) {
    __extends(MeetingOnJoinActionHandler, _super);
    function MeetingOnJoinActionHandler() {
        return _super.call(this, SocketActionType.MEETING_ON_JOIN) || this;
    }
    return MeetingOnJoinActionHandler;
}(SocketActionHandler));
export { MeetingOnJoinActionHandler };
var MeetingOnLeaveActionHandler = /** @class */ (function (_super) {
    __extends(MeetingOnLeaveActionHandler, _super);
    function MeetingOnLeaveActionHandler() {
        return _super.call(this, SocketActionType.MEETING_ON_LEAVE) || this;
    }
    return MeetingOnLeaveActionHandler;
}(SocketActionHandler));
export { MeetingOnLeaveActionHandler };
var MeetingOnUpdateActionHandler = /** @class */ (function (_super) {
    __extends(MeetingOnUpdateActionHandler, _super);
    function MeetingOnUpdateActionHandler() {
        return _super.call(this, SocketActionType.MEETING_ON_CHANGE) || this;
    }
    return MeetingOnUpdateActionHandler;
}(SocketActionHandler));
export { MeetingOnUpdateActionHandler };
var MeetingOnExcludeActionHandler = /** @class */ (function (_super) {
    __extends(MeetingOnExcludeActionHandler, _super);
    function MeetingOnExcludeActionHandler() {
        return _super.call(this, SocketActionType.MEETING_ON_EXCLUDE) || this;
    }
    return MeetingOnExcludeActionHandler;
}(SocketActionHandler));
export { MeetingOnExcludeActionHandler };
var MeetingSessionOnUpdateActionHandler = /** @class */ (function (_super) {
    __extends(MeetingSessionOnUpdateActionHandler, _super);
    function MeetingSessionOnUpdateActionHandler() {
        return _super.call(this, SocketActionType.MEETING_SESSION_ON_CHANGE) || this;
    }
    return MeetingSessionOnUpdateActionHandler;
}(SocketActionHandler));
export { MeetingSessionOnUpdateActionHandler };
var MeetingSessionOnCustomEventActionHandler = /** @class */ (function (_super) {
    __extends(MeetingSessionOnCustomEventActionHandler, _super);
    function MeetingSessionOnCustomEventActionHandler() {
        return _super.call(this, SocketActionType.MEETING_SESSION_ON_CUSTOM_EVENT) || this;
    }
    return MeetingSessionOnCustomEventActionHandler;
}(SocketActionHandler));
export { MeetingSessionOnCustomEventActionHandler };
var MeetingSessionOnStatsActionHandler = /** @class */ (function (_super) {
    __extends(MeetingSessionOnStatsActionHandler, _super);
    function MeetingSessionOnStatsActionHandler() {
        return _super.call(this, SocketActionType.MEETING_SESSION_ON_STATS) || this;
    }
    return MeetingSessionOnStatsActionHandler;
}(SocketActionHandler));
export { MeetingSessionOnStatsActionHandler };
var MeetingSessionMediaOnUpdateActionHandler = /** @class */ (function (_super) {
    __extends(MeetingSessionMediaOnUpdateActionHandler, _super);
    function MeetingSessionMediaOnUpdateActionHandler() {
        return _super.call(this, SocketActionType.MEETING_SESSION_MEDIA_ON_CHANGE) || this;
    }
    return MeetingSessionMediaOnUpdateActionHandler;
}(SocketActionHandler));
export { MeetingSessionMediaOnUpdateActionHandler };
