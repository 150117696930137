var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { io } from "socket.io-client";
import fetch from "cross-fetch";
import { EventEmitter } from "events";
import { EventKill, EventResponseMessage, SocketMessageStatus, WebSocketChannel } from "./constants";
import { delay, EventListeners, generateRandomId, validatePosNumber } from "./utils";
import { WebSocketEmitter } from "./emitter";
import { ConnectionError, ERROR_MESSAGE_SERVER_TIMEOUT, isTimeout, ApiErrorCode } from "./errors";
import { Logger } from "./logger";
import { WebSocketMiddlewares } from "./middlewares";
import { IncomingMessageWithResponse, OutgoingMessage, ResponseMessage, ResultEmittedMessage } from "./message";
;
;
;
var WebSocket = /** @class */ (function () {
    function WebSocket(options) {
        var _this = this;
        var _a;
        this.eventListener = new EventListeners();
        this._registeredActionHandlers = new Map();
        this._active = false;
        this._event = new EventEmitter();
        this._socket = null;
        this._connected = false;
        this._correlatedMessages = new Map();
        this._retryCurrentAmount = 0;
        this.onConnect = undefined;
        this.onDisconnect = undefined;
        this.onAbandon = undefined;
        /**
         * This method allows register a handler. You can only use one handler per "Socket action type"
         * @param handler Socket action handler
         */
        this.registerActionHandler = function (handler) {
            _this._registeredActionHandlers.set(handler.type, handler);
        };
        /**
         * This method allows register multiple handlers. You can only use one handler per "Socket action type"
         * @param handlers Socket action handlers
         */
        this.registerActionHandlers = function (handlers) {
            handlers.forEach(function (handler) {
                _this.registerActionHandler(handler);
            });
        };
        /**
         * This method allows you to retrieve a handler for a given action
         * @param type Socket action type
         */
        this.getActionHandler = function (type) { return _this._registeredActionHandlers.get(type); };
        /**
         * Returns a boolean promise that tells if the connection can
         * be retried. It includes a sleeping time and ensures that
         * the socket is still active after it.
         * Will call the onAbandon callback if the result is negative.
         */
        this.canRetry = function () { return __awaiter(_this, void 0, void 0, function () {
            var testRetry, result;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.logger.info("Retry connection ... ".concat(this._retryCurrentAmount + 1, "/").concat(this._retryMaxAmount));
                        testRetry = function () { return __awaiter(_this, void 0, void 0, function () {
                            var killed, killListener;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!this._active)
                                            return [2 /*return*/, false];
                                        killed = false;
                                        killListener = function () {
                                            killed = true;
                                        };
                                        if (this._event)
                                            this._event.addListener(EventKill, killListener);
                                        this._retryCurrentAmount += 1;
                                        if (this._retryMaxAmount >= 0 && this._retryCurrentAmount >= this._retryMaxAmount)
                                            return [2 /*return*/, false];
                                        return [4 /*yield*/, delay(this._retryDelay)];
                                    case 1:
                                        _a.sent();
                                        if (this._event)
                                            this._event.removeListener(EventKill, killListener);
                                        return [2 /*return*/, !killed && this._active];
                                }
                            });
                        }); };
                        return [4 /*yield*/, testRetry()];
                    case 1:
                        result = _b.sent();
                        if (!result) {
                            this.logger.info("Too many tentatives ... Abandon");
                            this.eventListener.triggerEvent('abandon');
                            (_a = this.onAbandon) === null || _a === void 0 ? void 0 : _a.call(this);
                        }
                        return [2 /*return*/, result];
                }
            });
        }); };
        this.resetRetries = function () {
            _this._retryCurrentAmount = 0;
        };
        this.logger = new Logger(false);
        this._url = options.url;
        this._retryMaxAmount = (_a = options.retryAmount) !== null && _a !== void 0 ? _a : 5;
        this._retryDelay = validatePosNumber(5000, options.retryDelay);
        this._timeoutCommand = validatePosNumber(15000, options.timeoutCommand);
        this._intervalCheckTimeoutCommand = setInterval(this.intervalCheckTimeoutCommandes.bind(this), 1000);
        this.emitter = new WebSocketEmitter(this);
        this.middlewares = new WebSocketMiddlewares(this);
    }
    Object.defineProperty(WebSocket.prototype, "connected", {
        get: function () {
            return this._connected;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * This method allows a user to connect to the server in websocket.
     * @param token User token
     * @param enpointGenerateSocketSessionToken Endpoint of the API which allows to obtain a Socket session token from a user token.
     * @returns Return a boolean value indicating if the socket is connecting or not.
     */
    WebSocket.prototype.connect = function (token, enpointGenerateSocketSessionToken, options) {
        return __awaiter(this, void 0, void 0, function () {
            var socketSessionToken, retry;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (options === null || options === void 0 ? void 0 : options.resetRetries)
                            this.resetRetries();
                        this._active = true;
                        return [4 /*yield*/, this.getSocketSessionToken(enpointGenerateSocketSessionToken, [["Authorization", "Bearer " + token]])];
                    case 1:
                        socketSessionToken = _a.sent();
                        retry = function () { return __awaiter(_this, void 0, void 0, function () {
                            var newData, _1;
                            var _a, _b, _c;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        _d.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, ((_a = options === null || options === void 0 ? void 0 : options.retryMiddleware) === null || _a === void 0 ? void 0 : _a.call(options))];
                                    case 1:
                                        newData = _d.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        _1 = _d.sent();
                                        return [3 /*break*/, 3];
                                    case 3: return [4 /*yield*/, this.connect((_b = newData === null || newData === void 0 ? void 0 : newData.token) !== null && _b !== void 0 ? _b : token, (_c = newData === null || newData === void 0 ? void 0 : newData.enpointGenerateSocketSessionToken) !== null && _c !== void 0 ? _c : enpointGenerateSocketSessionToken)];
                                    case 4: return [2 /*return*/, _d.sent()];
                                }
                            });
                        }); };
                        if (!!socketSessionToken) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.canRetry()];
                    case 2:
                        if (!_a.sent()) return [3 /*break*/, 4];
                        return [4 /*yield*/, retry()];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4: return [2 /*return*/, false];
                    case 5: return [4 /*yield*/, this.connectSocket(socketSessionToken, retry)];
                    case 6: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This method allows a user to connect to the server in websocket.
     * @param token User token
     * @param enpointGenerateSocketSessionToken Endpoint of the API which allows to obtain a Socket session token from a guest.
     * @returns Return a boolean value indicating if the socket is connecting or not.
     */
    WebSocket.prototype.connectAsGuest = function (nickname, enpointGenerateSocketSessionToken, options) {
        return __awaiter(this, void 0, void 0, function () {
            var socketSessionToken, retry;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (options === null || options === void 0 ? void 0 : options.resetRetries)
                            this.resetRetries();
                        this._active = true;
                        return [4 /*yield*/, this.getSocketSessionToken(enpointGenerateSocketSessionToken, undefined, { nickname: nickname })];
                    case 1:
                        socketSessionToken = _a.sent();
                        retry = function () { return __awaiter(_this, void 0, void 0, function () {
                            var newData, _2;
                            var _a, _b, _c;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        _d.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, ((_a = options === null || options === void 0 ? void 0 : options.retryMiddleware) === null || _a === void 0 ? void 0 : _a.call(options))];
                                    case 1:
                                        newData = _d.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        _2 = _d.sent();
                                        return [3 /*break*/, 3];
                                    case 3: return [4 /*yield*/, this.connectAsGuest((_b = newData === null || newData === void 0 ? void 0 : newData.nickname) !== null && _b !== void 0 ? _b : nickname, (_c = newData === null || newData === void 0 ? void 0 : newData.enpointGenerateSocketSessionToken) !== null && _c !== void 0 ? _c : enpointGenerateSocketSessionToken)];
                                    case 4: return [2 /*return*/, _d.sent()];
                                }
                            });
                        }); };
                        if (!!socketSessionToken) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.canRetry()];
                    case 2:
                        if (!_a.sent()) return [3 /*break*/, 4];
                        return [4 /*yield*/, retry()];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4: return [2 /*return*/, false];
                    case 5: return [4 /*yield*/, this.connectSocket(socketSessionToken, retry)];
                    case 6: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This method allows connecting to the server directly from a socket session token.
     * @param token Socket session token
     * @returns Return a boolean value indicating if the socket is connecting or not.
     */
    WebSocket.prototype.connectWitSocketToken = function (token, options) {
        return __awaiter(this, void 0, void 0, function () {
            var retry;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (options === null || options === void 0 ? void 0 : options.resetRetries)
                            this.resetRetries();
                        this._active = true;
                        retry = function () { return __awaiter(_this, void 0, void 0, function () {
                            var newData, _3;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _c.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, ((_a = options === null || options === void 0 ? void 0 : options.retryMiddleware) === null || _a === void 0 ? void 0 : _a.call(options))];
                                    case 1:
                                        newData = _c.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        _3 = _c.sent();
                                        return [3 /*break*/, 3];
                                    case 3: return [4 /*yield*/, this.connectWitSocketToken((_b = newData === null || newData === void 0 ? void 0 : newData.token) !== null && _b !== void 0 ? _b : token)];
                                    case 4: return [2 /*return*/, _c.sent()];
                                }
                            });
                        }); };
                        return [4 /*yield*/, this.connectSocket(token, retry)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This method allows to display or not the logs.
     * @param enabled Enable or not the debug mode
     */
    WebSocket.prototype.setDebugMode = function (enabled) {
        if (enabled === void 0) { enabled = true; }
        this.logger.enabled = enabled;
    };
    /**
     * This method sends a message to the server via the Socket.
     * @see {@link emitter} Use the "emitter" to send predefined commands. Example: socket.emitter.organization.getDevices()
     * @param type Socket action type
     * @param data The content of the command to send
     */
    WebSocket.prototype.emitMessage = function (action, data, options) {
        return __awaiter(this, void 0, void 0, function () {
            var command, promise;
            var _this = this;
            return __generator(this, function (_a) {
                command = this.createOutgoingMessage(action, data, options === null || options === void 0 ? void 0 : options.forceCorrelationId);
                promise = new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                    var timeout, result, listener, responseTimeout;
                    var _this = this;
                    return __generator(this, function (_a) {
                        result = new ResultEmittedMessage(this, command);
                        if (!this._socket) {
                            return [2 /*return*/, result];
                        }
                        listener = function (data) {
                            if (data.correlationId !== command.correlationId)
                                return;
                            _this._event.removeListener(EventResponseMessage, listener);
                            if (timeout)
                                clearTimeout(timeout);
                            if (data.type === SocketMessageStatus.SUCCESS) {
                                result.response = data.content;
                            }
                            else {
                                result.error = data.content;
                                if (result.error)
                                    result.error.timeout = isTimeout(result.error);
                            }
                            resolve(result);
                        };
                        responseTimeout = function () {
                            result.error = ERROR_MESSAGE_SERVER_TIMEOUT;
                            resolve(result);
                            _this._event.removeListener(EventResponseMessage, listener);
                        };
                        timeout = setTimeout(responseTimeout, (options === null || options === void 0 ? void 0 : options.timeout) && (options === null || options === void 0 ? void 0 : options.timeout) > 0 ? options === null || options === void 0 ? void 0 : options.timeout : this._timeoutCommand);
                        this._event.addListener(EventResponseMessage, listener);
                        // Emit Message
                        this.emit(WebSocketChannel.MESSAGE, command);
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, promise];
            });
        });
    };
    /**
    * This method sends a response message to the server via the Socket.
    * @param type Socket status
    * @param data The content of the command to send
    */
    WebSocket.prototype.emitResponseMessage = function (correlationId, type, data) {
        if (!this._socket)
            return false;
        var responseMessage = new ResponseMessage(this, {
            correlationId: correlationId,
            type: type,
            content: data,
        });
        // Emit Message
        this._socket.emit(WebSocketChannel.MESSAGE, responseMessage.toJSON());
        return true;
    };
    /**
     * This method allows you to emit any on socket
     */
    WebSocket.prototype.emit = function (event) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (!this._socket)
            return;
        (_a = this._socket).emit.apply(_a, __spreadArray([event], args, false));
    };
    WebSocket.prototype.addEventListener = function (event, listener) {
        if (!listener)
            return;
        this.eventListener.addEventListerner(event, listener);
    };
    WebSocket.prototype.removeEventListener = function (event, listener) {
        if (!listener)
            return;
        this.eventListener.removeEventListener(event, listener);
    };
    WebSocket.prototype.removeAllEventListeners = function () {
        this.eventListener.removeAllEventListeners();
    };
    /**
     * This method disconnects the socket
     */
    WebSocket.prototype.disconnect = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                this._active = false;
                this._event.emit(EventKill);
                (_a = this._socket) === null || _a === void 0 ? void 0 : _a.disconnect();
                return [2 /*return*/];
            });
        });
    };
    WebSocket.prototype.intervalCheckTimeoutCommandes = function () {
        var _this = this;
        var timeoutCommandes = [];
        var now = new Date();
        this._correlatedMessages.forEach(function (message, correlationId) {
            var createdAt = message.createdAt;
            var diff = now.getTime() - createdAt.getTime();
            if (diff > _this._timeoutCommand) {
                timeoutCommandes.push(correlationId);
            }
        });
        timeoutCommandes.forEach(function (correlationId) {
            var _a;
            var message = _this._correlatedMessages.get(correlationId);
            _this._correlatedMessages.delete(correlationId);
            if (!message)
                return;
            var actionHandler = _this.getActionHandler(message.action);
            if (actionHandler) {
                if (!("onResponse" in actionHandler))
                    return;
                var responseReceiver = actionHandler;
                (_a = responseReceiver.onResponseError) === null || _a === void 0 ? void 0 : _a.call(responseReceiver, ERROR_MESSAGE_SERVER_TIMEOUT, message.content);
            }
        });
    };
    WebSocket.prototype.getSocketSessionToken = function (url, header, params) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var requestInit, urlObj, key, response, success, err_1, data, err_2;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        requestInit = {
                            headers: header,
                        };
                        urlObj = new URL(url);
                        if (params) {
                            for (key in params) {
                                urlObj.searchParams.set(key, params[key]);
                            }
                        }
                        success = true;
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetch(urlObj.href, requestInit)];
                    case 2:
                        response = _f.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _f.sent();
                        success = false;
                        this.logger.info("Error catched during getting Socket session token : ", err_1);
                        this.eventListener.triggerEvent('connect', false, ConnectionError.NETWORK);
                        (_a = this.onConnectionFailed) === null || _a === void 0 ? void 0 : _a.call(this, ConnectionError.NETWORK);
                        return [3 /*break*/, 4];
                    case 4:
                        if (!success || !response)
                            return [2 /*return*/, undefined];
                        if (response.status === 460) {
                            this.eventListener.triggerEvent('connect', false, ConnectionError.TOKEN_EXPIRED);
                            (_b = this.onConnectionFailed) === null || _b === void 0 ? void 0 : _b.call(this, ConnectionError.TOKEN_EXPIRED);
                            this.logger.info("User token expired - error 460");
                            throw new Error("User token expired - error 460");
                        }
                        if (response.status === 403) {
                            this.eventListener.triggerEvent('connect', false, ConnectionError.UNAUTHORIZED);
                            (_c = this.onConnectionFailed) === null || _c === void 0 ? void 0 : _c.call(this, ConnectionError.UNAUTHORIZED);
                            this.logger.info("User unauthorized - error 403");
                            throw new Error("User unauthorized - error 403");
                        }
                        if (!response.ok) {
                            this.logger.info("Error getting Socket session token, response is incorrect : ", response);
                            this.eventListener.triggerEvent('connect', false, ConnectionError.NETWORK);
                            (_d = this.onConnectionFailed) === null || _d === void 0 ? void 0 : _d.call(this, ConnectionError.NETWORK);
                            return [2 /*return*/, undefined];
                        }
                        _f.label = 5;
                    case 5:
                        _f.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, response.json()];
                    case 6:
                        data = _f.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        err_2 = _f.sent();
                        this.logger.info("Error catched during parse response : ", err_2);
                        this.eventListener.triggerEvent('connect', false, ConnectionError.UNKNOW);
                        (_e = this.onConnectionFailed) === null || _e === void 0 ? void 0 : _e.call(this, ConnectionError.UNKNOW);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/, data === null || data === void 0 ? void 0 : data.token];
                }
            });
        });
    };
    /**
     * @param token Socket session token
     * @returns Return a boolean value indicating if the socket is connecting or not.
     */
    WebSocket.prototype.connectSocket = function (token, retry) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, resetRetriesTimeout;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._active = true;
                        _a = this;
                        return [4 /*yield*/, this.initSocket(token)];
                    case 1:
                        _a._socket = _b.sent();
                        if (!this._socket)
                            return [2 /*return*/, false];
                        // Channel listeners :
                        this._socket.on("connect", function () { return __awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return __generator(this, function (_a) {
                                this.logger.info("Socket connected");
                                this._connected = true;
                                this.eventListener.triggerEvent('connect', true);
                                if (this.onConnect)
                                    this.onConnect();
                                // To be sure that the connection is maintained (exemple : error during authentification)
                                resetRetriesTimeout = setTimeout(function () {
                                    _this.resetRetries();
                                    resetRetriesTimeout = undefined;
                                }, 500);
                                return [2 /*return*/];
                            });
                        }); });
                        this._socket.on("disconnect", function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.logger.info("Socket disconnected");
                                        if (resetRetriesTimeout)
                                            clearTimeout(resetRetriesTimeout);
                                        this.eventListener.triggerEvent('disconnect');
                                        if (this.onDisconnect)
                                            this.onDisconnect();
                                        return [4 /*yield*/, this.canRetry()];
                                    case 1:
                                        if (_a.sent()) {
                                            retry === null || retry === void 0 ? void 0 : retry();
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        this._socket.on("connect_error", function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.logger.info("Connection Failed : SOCKET");
                                        if (resetRetriesTimeout)
                                            clearTimeout(resetRetriesTimeout);
                                        this.eventListener.triggerEvent('connect', false, ConnectionError.SOCKET);
                                        if (this.onConnectionFailed)
                                            this.onConnectionFailed(ConnectionError.SOCKET);
                                        return [4 /*yield*/, this.canRetry()];
                                    case 1:
                                        if (_a.sent()) {
                                            retry === null || retry === void 0 ? void 0 : retry();
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        this._socket.on(WebSocketChannel.MESSAGE, function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (args.length === 0)
                                        return [2 /*return*/];
                                    this.logger.info("Socket message : ", args[0]);
                                    this.receiveMessage(args[0]);
                                    return [2 /*return*/];
                                });
                            });
                        });
                        this._socket.on(WebSocketChannel.ERROR, function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (args.length === 0)
                                        return [2 /*return*/];
                                    this.logger.info("Socket ERROR message : ", args[0]);
                                    this.receiveErrorMessage(args[0]);
                                    return [2 /*return*/];
                                });
                            });
                        });
                        this._socket.onAny(function (event) {
                            var _a;
                            var args = [];
                            for (var _i = 1; _i < arguments.length; _i++) {
                                args[_i - 1] = arguments[_i];
                            }
                            (_a = _this.eventListener).triggerEvent.apply(_a, __spreadArray(['message', event], args, false));
                        });
                        return [2 /*return*/, true];
                }
            });
        });
    };
    WebSocket.prototype.initSocket = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            var tryInit, socket, retry;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tryInit = function () { return __awaiter(_this, void 0, void 0, function () {
                            var socketUrl;
                            return __generator(this, function (_a) {
                                try {
                                    socketUrl = new URL(this._url);
                                    socketUrl.searchParams.set("token", token);
                                    return [2 /*return*/, io(socketUrl.href, { reconnection: false, transports: ["websocket"] })];
                                }
                                catch (error) {
                                    return [2 /*return*/, null];
                                }
                                return [2 /*return*/];
                            });
                        }); };
                        socket = null;
                        retry = false;
                        _a.label = 1;
                    case 1: return [4 /*yield*/, tryInit()];
                    case 2:
                        // eslint-disable-next-line no-await-in-loop
                        socket = _a.sent();
                        if (!!socket) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.canRetry()];
                    case 3:
                        // eslint-disable-next-line no-await-in-loop
                        retry = _a.sent();
                        _a.label = 4;
                    case 4:
                        if (retry) return [3 /*break*/, 1];
                        _a.label = 5;
                    case 5: return [2 /*return*/, socket];
                }
            });
        });
    };
    WebSocket.prototype.createOutgoingMessage = function (action, data, correlationId) {
        var message = new OutgoingMessage(this, {
            correlationId: correlationId !== null && correlationId !== void 0 ? correlationId : generateRandomId(),
            action: action,
            content: data,
        });
        this._correlatedMessages.set(message.correlationId, message);
        return message;
    };
    WebSocket.prototype.receiveIncomingMessage = function (data) {
        // Action received from server
        var actionHandler = this.getActionHandler(data.action);
        if (!actionHandler) {
            // No registered handler found for this action
            return;
        }
        if (!("onReceive" in actionHandler)) {
            // Not an action receiver
            return;
        }
        data = this.middlewares.processIncomingMessage(data);
        var actionReceiver = actionHandler;
        actionReceiver.onReceive(data);
    };
    WebSocket.prototype.receiveResponseMessage = function (data) {
        var _a;
        if (!data.correlationId)
            return;
        var correlationId = data.correlationId;
        var correlatedMsg = this._correlatedMessages.get(correlationId);
        if (!correlatedMsg) {
            // Error no correlated message
            return;
        }
        data = this.middlewares.processResponseMessage(correlatedMsg.action, data);
        this._event.emit(EventResponseMessage, data);
        this._correlatedMessages.delete(correlationId);
        var actionHandler = this.getActionHandler(correlatedMsg.action);
        if (!actionHandler) {
            // No registered handler found for this action
            return;
        }
        if (!("onResponse" in actionHandler)) {
            // Not a response receiver
            return;
        }
        var responseReceiver = actionHandler;
        if (data.type === SocketMessageStatus.SUCCESS) {
            responseReceiver.onResponse(data, correlatedMsg);
        }
        else {
            var errorMessage = data.content;
            if (errorMessage)
                errorMessage.timeout = isTimeout(errorMessage);
            (_a = responseReceiver.onResponseError) === null || _a === void 0 ? void 0 : _a.call(responseReceiver, errorMessage, correlatedMsg);
        }
    };
    WebSocket.prototype.receiveMessage = function (data) {
        if ("action" in data) {
            this.receiveIncomingMessage(new IncomingMessageWithResponse(this, data));
        }
        else if ("correlationId" in data) {
            this.receiveResponseMessage(new ResponseMessage(this, data));
        }
    };
    WebSocket.prototype.receiveErrorMessage = function (data) {
        var _a, _b;
        if (data)
            data.timeout = isTimeout(data);
        this.eventListener.triggerEvent('error', data);
        (_a = this.onErrorMessageCallback) === null || _a === void 0 ? void 0 : _a.call(this, data);
        if (data.code === -ApiErrorCode.SOCKET_AUTHENTICATION || data.code === -ApiErrorCode.SOCKET_UNAUTHORIZED) {
            this.logger.info("Connection socket unauthorized");
            this.eventListener.triggerEvent('connect', false, ConnectionError.SOCKET_UNAUTHORIZED);
            (_b = this.onConnectionFailed) === null || _b === void 0 ? void 0 : _b.call(this, ConnectionError.SOCKET_UNAUTHORIZED);
        }
    };
    return WebSocket;
}());
export { WebSocket };
export default WebSocket;
