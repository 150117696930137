var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var validatePosNumber = function (defValue, value) {
    if (value && value >= 0)
        return value;
    return defValue;
};
export var delay = function (ms) { return new Promise(function (res) { setTimeout(res, ms); }); };
export var generateRandomId = function (digit) {
    if (digit === void 0) { digit = 16; }
    return __spreadArray([], Array(digit), true).map(function () { return Math.floor(Math.random() * 16).toString(16); }).join('');
};
export var stringToDate = function (str) {
    if (!str)
        return null;
    return new Date(str);
};
/*
export interface MyEventListenerMap {
  'message': (action: string, params?: any) => void;
}
const a = new EventListeners<keyof MyEventListenerMap, MyEventListenerMap>();
a.addEventListerner('message', (action: string, params?: any) => console.log('Action : ', action, ' Params : ', params));
a.triggerEvent('message', 'action', { example: 4 });
*/
var EventListeners = /** @class */ (function () {
    function EventListeners() {
        this._listeners = {};
    }
    Object.defineProperty(EventListeners.prototype, "listener", {
        get: function () { return this._listeners; },
        enumerable: false,
        configurable: true
    });
    EventListeners.prototype.triggerEvent = function (key) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        (_a = this._listeners[key]) === null || _a === void 0 ? void 0 : _a.forEach(function (listener) { return listener.apply(void 0, args); });
    };
    EventListeners.prototype.addEventListerner = function (key, callback) {
        var _a;
        if (!this._listeners[key]) {
            this._listeners[key] = [callback];
            return [key, callback];
        }
        (_a = this._listeners[key]) === null || _a === void 0 ? void 0 : _a.push(callback);
        return [key, callback];
    };
    EventListeners.prototype.removeEventListener = function (key, callback) {
        var listeners = this._listeners[key];
        if (!listeners)
            return;
        listeners = listeners.filter(function (listener) { return listener !== callback; });
        this._listeners[key] = listeners;
    };
    EventListeners.prototype.removeAllEventListeners = function () {
        this._listeners = {};
    };
    return EventListeners;
}());
export { EventListeners };
