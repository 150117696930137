var _a;
import { formatDeviceState } from "../../service";
import { SocketActionType } from "../../constants";
var deviceOnStateChangeMiddleware = function (data) {
    data.content = formatDeviceState(data.content);
    return data;
};
export var deviceMiddlewares = {
    action: (_a = {},
        _a[SocketActionType.DEVICE_STATE_CHANGE] = deviceOnStateChangeMiddleware,
        _a)
};
